import {Injectable} from '@angular/core';
import 'rxjs/add/operator/toPromise';
import {AngularFireAuth} from '@angular/fire/auth';
import * as firebase from 'firebase/app';
import {PlatformService} from '../api/platform.service';

@Injectable()
export class AuthService {
  constructor(public afAuth: AngularFireAuth, private ps: PlatformService) {}

  doFacebookLogin() {
    return new Promise<any>((resolve, reject) => {
      const provider = new firebase.auth.FacebookAuthProvider();
      this.afAuth.signInWithPopup(provider).then(
        (res) => {
          return this.afAuth.fetchSignInMethodsForEmail(res.user.email).then((methods) => {
            if (!methods.includes('password')) {
              res.user.delete();
              reject({code: 'auth/no-user-account'});
            } else {
              resolve(res);
            }
          });
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  signUpFacebook() {
    return new Promise<any>((resolve, reject) => {
      const provider = new firebase.auth.FacebookAuthProvider();
      provider.addScope('manage_pages');
      provider.addScope('pages_messaging');
      this.afAuth.signInWithPopup(provider).then(
        (res) => {
          resolve(res);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  doGoogleLogin() {
    return new Promise<any>((resolve, reject) => {
      const provider = new firebase.auth.GoogleAuthProvider();
      provider.addScope('profile');
      provider.addScope('email');
      this.afAuth.signInWithPopup(provider).then(
        (res) => {
          return this.afAuth.fetchSignInMethodsForEmail(res.user.email).then((methods) => {
            if (!methods.includes('password')) {
              console.log('deleted account');
              // res.user.delete();
              reject({code: 'auth/no-user-account'});
            } else {
              resolve(res);
            }
          });
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  doRegister(value) {
    return new Promise<any>((resolve, reject) => {
      firebase
        .auth()
        .createUserWithEmailAndPassword(value.email, value.password)
        .then(
          (res) => {
            resolve(res);
          },
          (err) => reject(err)
        );
    });
  }

  doLogin(value, linkWithFacebook, pendingCred) {
    return new Promise<any>((resolve, reject) => {
      if (value.email.endsWith('@bookline.io') || value.email.endsWith('@bookline.ai')) {
        firebase
          .auth()
          .signInWithEmailAndPassword(value.email, value.password)
          .then(
            async (res) => {
              const user = await this.ps.getUser(res.user.uid).toPromise();
              if (user?.role === 'ADMIN') {
                resolve(res);
              } else {
                this.afAuth.signOut();
                reject('Not admin');
              }
            },
            (err) => reject(err)
          );
      } else reject('unauthorized');
    });
  }

  doLogout() {
    return new Promise((resolve, reject) => {
      if (firebase.auth().currentUser) {
        this.afAuth.signOut();
        resolve(0);
      } else {
        reject();
      }
    });
  }
}
