import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {ControlContainer, FormGroup, FormGroupDirective} from '@angular/forms';
import {NbDialogService} from '@nebular/theme';
import {LanguageEnum, LanguageEnumOptions, LanguageMap, LanguageValue} from '../../../@core/data/model-utils.model';
import {LanguageMapStringEditorDialogComponent} from './language-map-string-editor/language-map-string-editor-dialog.component';
import {Subscription} from 'rxjs';

// https://stackoverflow.com/questions/52893088/forwarding-formcontrolname-to-inner-component-in-angular-with-reactive-forms
@Component({
  selector: 'ngx-language-value-string',
  styleUrls: ['./language-value-string.component.scss'],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    },
  ],
  templateUrl: './language-value-string.component.html',
})
export class LanguageValueStringComponent implements OnInit, OnChanges {
  // @ViewChild('input', { static: true }) input: ElementRef;
  // @Output() search: EventEmitter<string> = new EventEmitter<string>();
  @Input() controlName: string;
  @Input() enabledLanguages?: LanguageEnum[];
  @Input() selectedLanguage: LanguageEnum;
  @Input() formGroup: FormGroup;
  @Input() popUpTitle?: string;
  @Input() placeholderLanguageMap?: LanguageMap<string>;
  @Input() allow_translate?: boolean;

  form: FormGroup;
  singleValue: string;
  mapValue: LanguageMap<string>;
  mapModeToggle: boolean;
  watchFormGroupChanges: boolean = false;
  constructor(private dialogService: NbDialogService, public controlContainer: ControlContainer) {}

  ngOnInit() {
    if (this.formGroup != null) {
      this.form = this.formGroup;
      this.watchFormGroupChanges = true;
    } else {
      this.form = <FormGroup>this.controlContainer.control;
    }
    // Sanitize control as it may be undefined
    if (this.controlValue == null) {
      this.form.get(this.controlName).setValue('');
    }
    if (this.enabledLanguages == null) {
      this.enabledLanguages = [...LanguageEnumOptions];
    }
    this.updateSingleMapValues(this.controlValue);
    this.mapModeToggle = !this.isControlValueSingle;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.watchFormGroupChanges) {
      for (const propertyName in changes) {
        if (propertyName == 'formGroup') {
          this.ngOnInit();
          break;
        }
      }
    }
  }

  updateSingleMapValues(new_value: any) {
    if (typeof new_value === 'string') {
      this.singleValue = new_value;
      const sensible_default_language = this.selectedLanguage ?? this.enabledLanguages[0];
      this.mapValue = {};
      this.mapValue[sensible_default_language] = this.singleValue;
    } else {
      this.mapValue = new_value;
      let sensible_default_value = this.extractAnyValue(this.mapValue);
      if (this.selectedLanguage && Object.keys(this.mapValue).includes(this.selectedLanguage)) {
        sensible_default_value = this.mapValue[this.selectedLanguage];
      }
      this.singleValue = sensible_default_value;
    }
  }

  openLanguageMapStringEditor() {
    this.dialogService
      .open(LanguageMapStringEditorDialogComponent, {
        context: {
          title: 'Edit Language Map - ' + (this.popUpTitle != null ? this.popUpTitle : this.controlName),
          value: this.controlValue,
          enabledLanguages: this.enabledLanguages,
          allow_translate: this.allow_translate,
        },
      })
      .onClose.subscribe((value) => {
        if (value != null) {
          this.mapValue = value;
          this.form.get(this.controlName).setValue(this.mapValue);
        }
      });
  }

  get placeholderText(): string {
    if (this.placeholderLanguageMap != null) {
      if (this.selectedLanguage in this.placeholderLanguageMap) {
        return this.placeholderLanguageMap[this.selectedLanguage];
      }
    }
    return '';
  }

  get controlValue(): any {
    return this.form.value[this.controlName];
  }

  /** If the control value is a single value and not a map, return true */
  get isControlValueSingle(): boolean {
    return typeof this.controlValue === 'string' || this.controlValue instanceof String;
  }

  get selectedLanguageValue() {
    if (this.isControlValueSingle) {
      return this.controlValue;
    }
    if (!this.selectedLanguage) {
      return this.extractAnyValue(this.controlValue);
    }
    if (Object.keys(this.controlValue).includes(this.selectedLanguage)) {
      return this.controlValue[this.selectedLanguage];
    }
  }

  extractAnyValue(languageMap: LanguageMap<string>, prepend_language?: boolean, default_value?: string): string {
    for (const [language, value] of Object.entries(languageMap)) {
      return prepend_language ? language + ':' + value : value;
    }
    return default_value ?? '';
  }

  onChangeModeToggle($event: boolean): void {
    if (this.mapModeToggle) {
      this.mapValue = this.controlValue;
    } else {
      this.singleValue = this.controlValue;
    }
    this.mapModeToggle = $event;
    if (this.mapModeToggle) {
      this.form.get(this.controlName).setValue(this.mapValue);
    } else {
      this.form.get(this.controlName).setValue(this.singleValue);
    }
  }
}
