import {FormArray, FormBuilder, FormGroup} from '@angular/forms';
import {VenueGroup} from '../../../@core/api/platform.service';
import * as uuid from 'uuid';

export function defaultVenueGroup(): VenueGroup {
  return {
    id: uuid.v4(),
    name: '',
    customer_id: '',
    venue_ids: ['', ''],
  };
}

export function profileToFormGroup(profile: VenueGroup, formBuilder: FormBuilder): FormGroup {
  const venue_ids = profile.venue_ids;
  profile.venue_ids = [];
  const formGroup = formBuilder.group(profile);
  formGroup.setControl(
    'venue_ids',
    formBuilder.array(
      venue_ids.map((venue_id) =>
        formBuilder.group({
          id: [venue_id],
        })
      )
    )
  );
  return formGroup;
}

export function profileFromFormGroup(profileFormGroup: FormGroup): VenueGroup {
  const newVenue = {
    id: profileFormGroup.get('id').value,
    name: profileFormGroup.get('name').value,
    customer_id: profileFormGroup.get('customer_id').value,
    venue_ids: profileFormGroup.get('venue_ids').value?.map((v) => v.id),
  };
  if (newVenue.id == null || newVenue.id.trim() === '') {
    throw {message: 'Invalid Venue ID'};
  } else if (newVenue.name == null || newVenue.name.trim() === '') {
    throw {message: 'Invalid Venue Name'};
  } else if (newVenue.customer_id == null || newVenue.customer_id.trim() === '') {
    throw {message: 'Invalid Customer ID'};
  } else if (newVenue.venue_ids == null || newVenue.venue_ids.length < 2) {
    throw {message: 'A venue group needs at least 2 venues'};
  }
  return newVenue;
}
