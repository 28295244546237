import {Component, Input, OnInit, OnDestroy} from '@angular/core';
import {FormBuilder, FormGroup, FormArray} from '@angular/forms';
import {NbDialogRef} from '@nebular/theme';
import {NbToastrService} from '@nebular/theme';
import {TranslateService} from '../../../../@core/api/translate.service';
import {LanguageEnum, LanguageEnumCodeMap, LanguageEnumFlagIconMap, LanguageEnumOptions, LanguageMap} from '../../../../@core/data/model-utils.model';

@Component({
  selector: 'ngx-language-map-string-editor-dialog',
  templateUrl: './language-map-string-editor-dialog.component.html',
  styleUrls: ['./language-map-string-editor-dialog.component.scss'],
})
export class LanguageMapStringEditorDialogComponent implements OnInit, OnDestroy {
  @Input() title: string;
  @Input() value?: LanguageMap<string>;
  @Input() enabledLanguages?: LanguageEnum[];
  @Input() allow_translate?: boolean;

  constructor(protected ref: NbDialogRef<LanguageMapStringEditorDialogComponent>, private fb: FormBuilder, private toastrService: NbToastrService, private ts: TranslateService) {}

  languageForm: FormGroup;

  ngOnInit() {

    if (this.enabledLanguages == null) {
      this.enabledLanguages = [...LanguageEnumOptions];
    }
    this.languageForm = this.fb.group({
      languageValues: this.fb.array(this.enabledLanguages.map((language) => this.newLanguageFormGroup(language))),
    });

    if (this.allow_translate == null) {
      this.allow_translate = false;
    }

    // Prepopulate with existing data, if any provided
    if (this.value != null) {
      this.updateLanguageValuesForm(this.value);
    }
  }

  newLanguageFormGroup(lang: LanguageEnum, value?: string) {
    return this.fb.group({
      flagIcon: [LanguageEnumFlagIconMap[lang]],
      language: [lang],
      value: [value ?? ''],
    });
  }

  get languageValuesFormArray(): FormArray {
    return this.languageForm.get('languageValues') as FormArray;
  }

  get languageValuesMap(): LanguageMap<string> {
    const languageValuesMap: LanguageMap<string> = {};
    for (const control of this.languageValuesFormArray.controls) {
      languageValuesMap[control.get('language').value] = control.get('value').value ?? '';
    }
    return languageValuesMap;
  }

  updateLanguageValuesForm(languageValueMap: LanguageMap<string>) {
    const currentMap = this.languageValuesMap;

    for (const language of Object.keys(languageValueMap) as LanguageEnum[]) {
      if (this.enabledLanguages.some((lang) => lang == language)) {
        currentMap[language] = languageValueMap[language];
      }
    }
    this.languageForm.setControl('languageValues', this.fb.array((Object.keys(currentMap) as LanguageEnum[]).map((language) => this.newLanguageFormGroup(language, currentMap[language]))));
  }

  decodeHtmlEntities(input) {
    const entities = {
      amp: '&',
      apos: "'",
      lt: '<',
      gt: '>',
      quot: '"',
      '#39': "'",
      '#34': '"',
    };

    return input.replace(/&([^;]+);/g, function (match, entity) {
      return entities[entity] || match;
    });
  }

  async translateText(index: number) {
    const formArray = this.languageValuesFormArray;
    const currentLang = formArray.at(index);
    const textToTranslate = currentLang.get('value').value;

    try {
      for (let i = 0; i < formArray.length; i++) {
        if (i !== index) {
          if (formArray.at(i).get('value').value === '') {
            const targetLangCode = LanguageEnumCodeMap[formArray.at(i).get('language').value];
            const response = await this.ts.translateText(textToTranslate, targetLangCode).toPromise();
            const translatedText = this.decodeHtmlEntities(response.data.translations[0].translatedText);
            formArray.at(i).get('value').setValue(translatedText);
          }
        }
      }
    } catch (error) {
      this.toastrService.show('Translation Error', error.message, {status: 'danger'});
    }
  }

  save() {
    this.ref.close(this.languageValuesMap);
  }

  dismiss() {
    this.ref.close(null);
  }

  clear() {
    this.ref.close({});
  }

  ngOnDestroy() {}
}
