import {Component, Input, OnInit, SimpleChanges} from '@angular/core';
import {ControlContainer, FormGroup, FormGroupDirective} from '@angular/forms';
import {NbDialogService} from '@nebular/theme';
import {ClientService} from '../../../@core/firestore/clients.service';
import {Schedule, Shift} from '../../../@core/data/client.model';
import {ScheduleDialogComponent} from '../../../portal/restaurant-bots/schedule/schedule-dialog.component';

const OldDayOptions = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'] as const;

// https://stackoverflow.com/questions/52893088/forwarding-formcontrolname-to-inner-component-in-angular-with-reactive-forms
@Component({
  selector: 'ngx-schedule-editor-legacy',
  styleUrls: ['./schedule-editor-legacy.component.scss'],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    },
  ],
  templateUrl: './schedule-editor-legacy.component.html',
})
export class ScheduleEditorLegacyComponent implements OnInit {
  @Input() formGroup: FormGroup;
  @Input() controlName: string;
  @Input() title?: string;

  form: FormGroup;
  watchFormGroupChanges: boolean = false;
  constructor(private dialogService: NbDialogService, public controlContainer: ControlContainer, private clientService: ClientService) {}

  ngOnInit() {
    if (this.formGroup != null) {
      this.form = this.formGroup;
      this.watchFormGroupChanges = true;
    } else {
      this.form = <FormGroup>this.controlContainer.control;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.watchFormGroupChanges) {
      for (const propertyName in changes) {
        if (propertyName === 'formGroup') {
          this.ngOnInit();
          break;
        }
      }
    }
  }

  get controlValue(): Schedule {
    return this.form.get(this.controlName).value;
  }

  get ScheduleDescription(): {[k: string]: string} {
    return this.clientService.getScheduleDescriptor(this.controlValue);
  }

  get isEmptySchedule(): boolean {
    const schedule = this.controlValue;
    if (!schedule || !schedule.days) {
      return true;
    }
    let oneDayOpen = false;
    OldDayOptions.forEach((day) => {
      if (schedule.days[day]) {
        schedule.days[day].shifts.forEach((shift) => {
          oneDayOpen = oneDayOpen || (shift.start_time !== '' && shift.end_time !== '');
        });
      }
    });
    return !oneDayOpen;
  }

  openScheduleModal() {
    let title = `Add ${this.title ?? this.controlName} schedule`;

    this.dialogService
      .open(ScheduleDialogComponent, {
        context: {
          title: title,
          schedule: this.controlValue,
        },
      })
      .onClose.subscribe((result) => {
        if (result) {
          this.form.get(this.controlName).setValue(result);
          console.log(result);
        }
      });
  }
}
