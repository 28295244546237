import {environment} from '../../../environments/environment';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {AuthService} from '../firestore/auth.service';
import {Client, Schedule} from '../data/client.model';
import {LanguageEnum, LanguageMap} from '../data/model-utils.model';
import {HotelVoiceBotConfig} from '../data/hotel-bot.model';
import {IVRVoiceBotConfig} from '../data/ivr-bot.model';

type RestaurantBotType = 'RESTAURANT';
type FlexBotType = 'FLEXBOT';
type FlexBotV2Type = 'FLEXBOT_V2';
type HotelBotType = 'HOTEL';
type IVRBotType = 'IVR';
type BotType = RestaurantBotType | FlexBotType | HotelBotType | FlexBotV2Type | IVRBotType;
export const BotTypes = ['RESTAURANT', 'FLEXBOT', 'FLEXBOT_V2', 'HOTEL', 'IVR'] as const;

export const TTSProviders = ['AMAZON', 'GOOGLE', 'MICROSOFT', 'ELEVENLABS'] as const;
export type TTSProvidersType = (typeof TTSProviders)[number];

export const STTProviders = ['GOOGLE', 'MICROSOFT', 'DEEPGRAM'] as const;
export type STTProvidersType = (typeof STTProviders)[number];

export interface TTSConfig {
  provider: TTSProvidersType;
  language: LanguageEnum;
  voice_title: string;
  voice_speed: number;
  voice_volume: string;
}

export interface AmazonTTSConfig extends TTSConfig {
  neural: boolean;
}

export interface STTConfig {
  provider: STTProvidersType;
  language: LanguageEnum;
}

export interface VoiceSettings {
  tts: AmazonTTSConfig | TTSConfig;
  stt: STTConfig;
}

export type VoiceConfig = LanguageMap<VoiceSettings>;

interface BasicConfig {
  bot_type: BotType;
  default_language: LanguageEnum;
  integrations: (Integration | string)[];
  voice?: VoiceConfig;
  timezone: string;
}

export interface RestaurantBotConfig extends Client {
  bot_type: RestaurantBotType;
}

interface FlexBotConfig extends BasicConfig {
  bot_type: FlexBotType;
}

interface FlexBotV2Config {
  bot_type: FlexBotV2Type;
}

export interface HotelBotConfig extends BasicConfig, HotelVoiceBotConfig {
  bot_type: HotelBotType;
}

export interface IVRBotConfig extends BasicConfig, IVRVoiceBotConfig {
  bot_type: IVRBotType;
}

export interface Bot {
  id: string;
  owner_id: string;
  venue_id?: string;
  venue_group_id?: string;
  name: string;
  tags: string[];
  config: RestaurantBotConfig | FlexBotConfig | HotelBotConfig | IVRBotConfig | FlexBotV2Config;
}

export interface BotData {
  bot: Bot;
  assignments: Assignment[];
}

export interface Assignment {
  id?: string;
  channel_id: string;
  channel_type: 'PHONE' | 'WHATSAPP' | 'SLACK';
  bot_id: string;
  owner_id: string;
  config_override?: object;
  emergency_settings?: object;
}

export interface BotGroup {
  id: string;
  name: string;
  phonetics: string;
  bots: string[];
}

export interface IdNameData {
  id: string;
  name: string;
}

export interface VenueGroup {
  id: string;
  name: string;
  customer_id: string;
  venue_ids: string[];
}

export interface IdNameTagsData {
  id: string;
  name: string;
  tags: string[];
}

export interface IdOwnerData {
  id: string;
  owner_id: string;
}

export interface Venue {
  id: string;
  name: string;
  customer_id: string;
  crm_id: string;
  type: string;
}

export interface NotificationScheduleConfig {
  id: string;
  customer_id: string;
  integration_id: string;
  integration_type: string;
  frequency: string;
  candidate_query: {[key: string]: any};
  notification_sequence: {[key: string]: any};
  enabled: boolean;
}

export interface NotificationScheduleConfigSummary {
  id: string;
  frequency: string;
  enabled: boolean;
}

export interface NotificationRun {
  id: string;
  config: any;
  run_start: string;
  run_end: string;
  query_domain: any;
  query_range: any;
  notification_sequences: string[];
  status: string;
  error_data: any;
}

export interface NotificationRunSummary {
  id: string;
  run_start: string;
  notification_sequences: string[];
  status: string;
  error_data: any;
}

export interface Sequence {
  id: string;
  config: any;
  initiated_by: string;
  created_date: string;
  parent_run_id: string;
  source_object: any;
  notifications: string[];
  status: 'IN_PROGRESS' | 'ERROR' | 'FINISHED';
}

export interface Integration {
  id: string;
  name: string;
  config: any;
}

export interface Address {
  full_address: string;
  city: string;
  postal_code: string;
}

export interface DirectDebitDetails {
  payment_type: 'DIRECT_DEBIT';
  payment_due_days?: number;
  gocardless_client_id?: string;
}

export interface BankTransferDetails {
  payment_type: 'BANK_TRANSFER';
  payment_due_days?: number;
  bank_account?: string;
}

export interface CreditCardDetails {
  payment_type: 'CREDIT_CARD';
  payment_due_days?: number;
  stripe_client_id?: string;
}

export interface ConfirmingDetails {
  payment_type: 'CONFIRMING';
  payment_due_days?: number;
}

export type PaymentDetails = DirectDebitDetails | BankTransferDetails | CreditCardDetails | ConfirmingDetails;

export interface PriceHistory {
  start_date: string;
  end_date?: string;
  price_100: number;
  discount_100: number;
  billable: boolean;
  description?: string;
}

export type ServiceStatus = 'DRAFT' | 'TRIAL' | 'ENROLLED' | 'PAUSED' | 'CANCELLED';

export type ServiceType = 'VOICE_ASSISTANT' | 'PBX' | 'MESSAGES' | 'OTHER' | 'WHATSAPP_INBOUND' | 'WHATSAPP_OUTBOUND' | 'SMS_OUTBOUND';

export type BusinessLine = 'RESTAURANTS' | 'HOTELS' | 'B2B' | 'HEALTHCARE' | 'LOGISTICS';

export interface Service {
  service_type: ServiceType;
  bot_id?: string;
  price_history: PriceHistory[];
  status: ServiceStatus;
  business_line: BusinessLine;
}

export interface Account {
  id?: string;
  legal_id?: string;
  legal_name?: string;
  address?: Address;
  partner_full_name?: string;
  partner_legal_id?: string;
  accounting_email?: string[];
  signed_contract?: boolean;
  payment_details?: PaymentDetails;
  payment_frequency: 'monthly' | 'yearly';
  custom_invoice_reference: string;
  default_tax_rate: number;
  services: Service[];
  owner?: string; // added by us, not provided by papi
}

export interface CustomerOnboarding {
  config: any;
  status: string;
  entry_step: string;
}

export interface Customer {
  id?: string;
  name: string;
  accounts: Account[];
  crm_id: string;
  integrations: Integration[];
  integrations_v2?: any[];
  onboarding?: CustomerOnboarding;
  trial_end_date?: string | Date;
  trial_start_date?: string | Date;
}

export interface AccountData {
  customer_id: string;
  account: Account;
}

export interface PaginatedResponse<T> {
  data: T[];
  pagination: {total: number; limit?: number; offset?: number};
  filters?: object;
}

export const Carriers = [
  'VOXBONE',
  'NETELIP',
  'TWILIO',
  'LCR',
  'EXTERNAL_PBX',
  'MEGATROGRUP',
  'NUBELFON',
  'VILANOVAPARK',
  'VOIPSTUDIO',
  'VOXBONEUK',
  'VOXBONEGERMANY',
  'VOXBONEIT',
  'TELNYX',
  'TAXITRONIC',
  'MONKINET',
] as const;
export type CarriersType = (typeof Carriers)[number];

export const NumberStatus = ['ACTIVE', 'BLOCKED', 'INACTIVE', 'PENDING_DELETION'] as const;
export type NumberStatusType = (typeof NumberStatus)[number];

export const NumberType = ['PSTN', 'VIRTUAL'] as const;
export type NumberTypeType = (typeof NumberType)[number];

export const Platforms = ['JAMBONZ', 'KAMAILIO', 'VPBX', 'API_VOICE', 'MEET_IP', 'DIRECT_TRANSFER'] as const;
export type PlatformsType = (typeof Platforms)[number];

export interface BooklineNumber {
  id?: string;
  type: NumberTypeType;
  phone_number?: string | {e164: string; country_code?: string; national?: string};
  virtual_id?: string;
  owner_id: string;
  status?: NumberStatusType;
  carrier: CarriersType;
  carrier_settings?: {raw: any};
  pbx_integration_id?: string;
  platform_id?: string;
  meta_data?: {phone_number_sid: string};
  platform?: PlatformsType;
  platform_settings?: any;
  notes?: string;
}

export interface NumberWithAssignment {
  number: BooklineNumber;
  assignments: Assignment[];
}

export interface ReducedJambonzInput {
  call_status?: string;
  reason?: string;
  user_input?: string;
}

export interface ReducedJambonzOutput {
  action_type: string;
  action_data?: string | string[];
  extra_data?: string | string[];
}

export interface Interaction {
  timestamp: string;
  bot: BotType;
  bot_id: string;
  input: ReducedJambonzInput;
  output: ReducedJambonzOutput[];
}

export interface BotEvent {
  title: string;
  created: string;
  value: any;
}

export interface Call {
  id: string;
  destination: string;
  origin_bot_id: string;
  source: string;
  start: string;
  interactions?: Interaction[];
  events?: BotEvent[];
}

const configOptionsItems = ['hotel_reservation_url_types', 'camping_reservation_url_types', 'apartments_reservaton_url_types'] as const;
export type configOptionsItemsType = (typeof configOptionsItems)[number];

export interface ConfigOptionsResponse {
  data: any;
}

type boolInputPar = true | false;
type returnTypeGetBotWithAssigments<T> = T extends true ? BotData : Bot;
type returnTypeGetNumberWithAssigments<T> = T extends true ? NumberWithAssignment : BooklineNumber;

@Injectable({
  providedIn: 'root',
})
export class PlatformService {
  constructor(private http: HttpClient, private authService: AuthService) {}

  // MOTIVE_TO_ENUM = {
  //   'start': 'GREETING',
  //   'book': 'NONE_BOOK',
  //   'modify-cancel': 'NONE_BOOK',
  // };
  GREETING_TO_ENUM = {
    'Synth - Standard': 'STANDARD',
    'Synth - Busy': 'STANDARD',
    'Restaurant selection (audio ddi.mp3)': 'PLAY_AUDIO',
    'Language selection (audio ddi.mp3)': 'PLAY_AUDIO',
    'Action selection (audio ddi.mp3)': 'PLAY_AUDIO',
    'Synth - Custom Text': 'SYNTH_CUSTOM',
    'None - Reservation': 'STANDARD',
    'Synth - Custom JSON': 'SYNTH_CUSTOM',
  };

  public transformSchedule(schedule: Schedule) {
    return {
      always_open: schedule.always_enabled.value,
    };
  }

  // TODO: Hay que arreglar esta funcion para que no sea un Client, sino un Bot, mientras tanto creé putBotFixed
  public putBot(owner: string, bot: Client): Observable<HttpResponse<Bot>> {
    const bot_config: RestaurantBotConfig = {bot_type: 'RESTAURANT', ...bot};
    const new_bot = {
      id: bot.ID,
      owner_id: owner,
      name: bot.details.name,
      config: bot_config,
    };
    return this.http.put<Bot>(environment.api.papi + '/bots/' + bot.ID, new_bot, {observe: 'response'});
  }

  public putBotFixed(bot: Bot): Observable<HttpResponse<Bot>> {
    return this.http.put<Bot>(environment.api.papi + '/bots/' + bot.id, bot, {observe: 'response'});
  }
  public addBotObserveResponse(bot: Bot): Observable<HttpResponse<Bot>> {
    return this.http.post<Bot>(environment.api.papi + '/bots', bot, {observe: 'response'});
  }
  public addBot(bot: Bot): Observable<Bot> {
    return this.http.post<Bot>(environment.api.papi + '/bots', bot);
  }

  public putAssignment(assignment: Assignment): Observable<any> {
    return this.http.put<any>(environment.api.papi + '/assignments/' + assignment.id, assignment);
  }
  public addAssignment(assignment: Assignment): Observable<Assignment> {
    return this.http.post<Assignment>(environment.api.papi + '/assignments', assignment);
  }

  public deleteAssignment(assignment_id: string): Observable<any> {
    return this.http.delete(environment.api.papi + '/assignments/' + assignment_id);
  }

  public putVenue(venue: Venue): Observable<any> {
    return this.http.put<any>(environment.api.papi + '/venues/' + venue.id, venue);
  }
  public addVenue(venue: Venue): Observable<any> {
    return this.http.post<Venue>(environment.api.papi + '/venues', venue);
  }

  public deleteVenue(venue_id: string): Observable<any> {
    return this.http.delete(environment.api.papi + '/venues/' + venue_id);
  }

  public putVenueGroup(venue_group: VenueGroup): Observable<any> {
    return this.http.put<any>(environment.api.papi + '/venue-groups/' + venue_group.id, venue_group);
  }
  public addVenueGroup(venue_group: VenueGroup): Observable<any> {
    return this.http.post<VenueGroup>(environment.api.papi + '/venue-groups', venue_group);
  }

  public deleteVenueGroup(venue_id: string): Observable<any> {
    return this.http.delete(environment.api.papi + '/venue-groups/' + venue_id);
  }

  public getAssignments(bot_id?: string, channel_type?: 'PHONE' | 'WHATSAPP' | 'SLACK', start_after?: string, offset?: number): Observable<PaginatedResponse<Assignment>> {
    let endpoint = '/assignments';
    if (typeof bot_id !== 'undefined') {
      endpoint = endpoint + '?bot_id=' + bot_id;
    }
    if (typeof channel_type !== 'undefined') {
      endpoint = endpoint + bot_id ? '&' : '?' + 'channel_type=' + channel_type;
    }
    if (typeof start_after !== 'undefined') {
      endpoint = endpoint + '&start_after=' + start_after;
    }
    if (typeof offset !== 'undefined') {
      endpoint = endpoint + (bot_id ? '&offset=' : '?offset=') + Math.floor(offset).toString();
    }
    return this.http.get<PaginatedResponse<Assignment>>(environment.api.papi + endpoint);
  }

  public getAssingment(assignmentId: string): Observable<Assignment> {
    return this.http.get<Assignment>(environment.api.papi + '/assignments/' + assignmentId);
  }

  public async getAllAssignments(bot_id?: string, channel_type?: 'PHONE' | 'WHATSAPP' | 'SLACK'): Promise<Assignment[]> {
    let response_data = await this.getAssignments(bot_id, channel_type).toPromise();
    let results: Assignment[] = [];
    results.push(...response_data.data);
    if (response_data.pagination.total > response_data.data.length) {
      const offset_increment = response_data.pagination.limit ?? response_data.data.length;
      let offset = offset_increment;
      let promises: Promise<PaginatedResponse<Assignment>>[] = [];
      while (offset < response_data.pagination.total) {
        promises.push(this.getAssignments(bot_id, channel_type, undefined, offset).toPromise());
        offset += offset_increment;
      }
      for (const promise of promises) {
        results.push(...(await promise).data);
      }
    }
    return results;
  }
  public getAllBotGroups(): Observable<PaginatedResponse<BotGroup>> {
    return this.http.get<PaginatedResponse<BotGroup>>(environment.api.papi + '/botgroups');
  }
  public getBotGroup(groupId: string): Observable<BotGroup> {
    return this.http.get<BotGroup>(environment.api.papi + '/botgroups/' + groupId);
  }
  public deleteBotGroup(groupId: string): Observable<HttpResponse<Object>> {
    return this.http.delete(environment.api.papi + '/botgroups/' + groupId, {observe: 'response'});
  }
  public putBotGroup(groupId: string, botGroup: BotGroup): Observable<HttpResponse<Object>> {
    return this.http.put(environment.api.papi + '/botgroups/' + groupId, botGroup, {observe: 'response'});
  }
  public patchBotGroup(groupId: string, botGroup: BotGroup): Observable<any> {
    return this.http.patch(environment.api.papi + '/botgroups/' + groupId, botGroup);
  }
  public postBotGroup(botGroup: BotGroup): Observable<HttpResponse<Object>> {
    return this.http.post(environment.api.papi + '/botgroups', botGroup, {observe: 'response'});
  }
  public getBotAudit(botID: string): Observable<any> {
    return this.http.get(environment.api.papi + '/bots/' + botID + '/audit');
  }
  public getBot<T extends boolInputPar>(bot_id: string, withAssignments: T): Observable<HttpResponse<returnTypeGetBotWithAssigments<T>>> {
    let query = environment.api.papi + '/bots/' + bot_id;
    if (withAssignments) {
      query += '?assignments=true';
    }
    return this.http.get<returnTypeGetBotWithAssigments<T>>(query, {observe: 'response'});
  }
  public getAssignmentIds(bot_id?: string, channel_type?: 'PHONE' | 'WHATSAPP' | 'SLACK', channel_id?: string, start_after?: string, offset?: number): Observable<PaginatedResponse<string>> {
    let endpoint = '/assignments/ids?';
    if (typeof bot_id !== 'undefined') {
      endpoint = endpoint + '&bot_id=' + bot_id;
    }
    if (typeof channel_type !== 'undefined') {
      endpoint = endpoint + '&channel_type=' + channel_type;
    }
    if (typeof channel_id !== 'undefined') {
      endpoint = endpoint + '&channel_id=' + channel_id;
    }
    if (typeof start_after !== 'undefined') {
      endpoint = endpoint + '&start_after=' + start_after;
    }
    if (typeof offset !== 'undefined') {
      endpoint = endpoint + '&offset=' + Math.floor(offset).toString();
    }
    return this.http.get<PaginatedResponse<string>>(environment.api.papi + endpoint);
  }
  public async getAllAssignmentIds(bot_id?: string, channel_type?: 'PHONE' | 'WHATSAPP' | 'SLACK', channel_id?: string): Promise<string[]> {
    let response_data = await this.getAssignmentIds(bot_id, channel_type, channel_id, undefined, undefined).toPromise();
    let results: string[] = [];
    results.push(...response_data.data);
    if (response_data.pagination.total > response_data.data.length) {
      const offset_increment = response_data.pagination.limit ?? response_data.data.length;
      let offset = offset_increment;
      let promises: Promise<PaginatedResponse<string>>[] = [];
      while (offset < response_data.pagination.total) {
        promises.push(this.getAssignmentIds(bot_id, channel_type, channel_id, undefined, offset).toPromise());
        offset += offset_increment;
      }
      for (const promise of promises) {
        results.push(...(await promise).data);
      }
    }
    return results;
  }
  public getBotIds(bot_type?: BotType, owner_id?: string, start_after?: string, offset?: number, onlyDeleted?: boolean): Observable<PaginatedResponse<string>> {
    let endpoint = '/bots/ids?';
    if (typeof bot_type !== 'undefined') {
      endpoint = endpoint + '&bot_type=' + bot_type;
    }
    if (typeof owner_id !== 'undefined') {
      endpoint = endpoint + '&owner_id=' + owner_id;
    }
    if (typeof start_after !== 'undefined') {
      endpoint = endpoint + '&start_after=' + start_after;
    }
    if (typeof offset !== 'undefined') {
      endpoint = endpoint + '&offset=' + Math.floor(offset).toString();
    }
    if (onlyDeleted) {
      endpoint = endpoint + '&only_deleted=true';
    }
    return this.http.get<PaginatedResponse<string>>(environment.api.papi + endpoint);
  }
  public async getAllBotIds(bot_type?: BotType, owner_id?: string, onlyDeleted?: boolean): Promise<string[]> {
    let response_data = await this.getBotIds(bot_type, owner_id, undefined, undefined, onlyDeleted).toPromise();
    let results: string[] = [];
    results.push(...response_data.data);
    if (response_data.pagination.total > response_data.data.length) {
      const offset_increment = response_data.pagination.limit ?? response_data.data.length;
      let offset = offset_increment;
      let promises: Promise<PaginatedResponse<string>>[] = [];
      while (offset < response_data.pagination.total) {
        promises.push(this.getBotIds(bot_type, owner_id, undefined, offset, onlyDeleted).toPromise());
        offset += offset_increment;
      }
      for (const promise of promises) {
        results.push(...(await promise).data);
      }
    }
    return results;
  }
  public getBotIdsAndNames(bot_type?: BotType, owner_id?: string, start_after?: string, offset?: number): Observable<PaginatedResponse<IdNameData>> {
    let endpoint = '/bots/ids_names?';
    if (typeof bot_type !== 'undefined') {
      endpoint = endpoint + '&bot_type=' + bot_type;
    }
    if (typeof owner_id !== 'undefined') {
      endpoint = endpoint + '&owner_id=' + owner_id;
    }
    if (typeof start_after !== 'undefined') {
      endpoint = endpoint + '&start_after=' + start_after;
    }
    if (typeof offset !== 'undefined') {
      endpoint = endpoint + '&offset=' + Math.floor(offset).toString();
    }
    return this.http.get<PaginatedResponse<IdNameData>>(environment.api.papi + endpoint);
  }
  public async getAllBotIdsAndNames(bot_type?: BotType, owner_id?: string): Promise<IdNameData[]> {
    let response_data = await this.getBotIdsAndNames(bot_type, owner_id).toPromise();
    let results: IdNameData[] = [];
    results.push(...response_data.data);
    if (response_data.pagination.total > response_data.data.length) {
      const offset_increment = response_data.pagination.limit ?? response_data.data.length;
      let offset = offset_increment;
      let promises: Promise<PaginatedResponse<IdNameData>>[] = [];
      while (offset < response_data.pagination.total) {
        promises.push(this.getBotIdsAndNames(bot_type, owner_id, undefined, offset).toPromise());
        offset += offset_increment;
      }
      for (const promise of promises) {
        results.push(...(await promise).data);
      }
    }
    return results;
  }

  public getBotIdsAndNamesAndTags(bot_type?: BotType, owner_id?: string, start_after?: string, offset?: number): Observable<PaginatedResponse<IdNameTagsData>> {
    let endpoint = '/bots/ids_names_tags?';
    if (typeof bot_type !== 'undefined') {
      endpoint = endpoint + '&bot_type=' + bot_type;
    }
    if (typeof owner_id !== 'undefined') {
      endpoint = endpoint + '&owner_id=' + owner_id;
    }
    if (typeof start_after !== 'undefined') {
      endpoint = endpoint + '&start_after=' + start_after;
    }
    if (typeof offset !== 'undefined') {
      endpoint = endpoint + '&offset=' + Math.floor(offset).toString();
    }
    return this.http.get<PaginatedResponse<IdNameTagsData>>(environment.api.papi + endpoint);
  }

  public async getAllBotIdsAndNamesAndTags(bot_type?: BotType, owner_id?: string): Promise<IdNameTagsData[]> {
    let response_data = await this.getBotIdsAndNamesAndTags(bot_type, owner_id).toPromise();
    let results: IdNameTagsData[] = [];
    results.push(...response_data.data);
    if (response_data.pagination.total > response_data.data.length) {
      const offset_increment = response_data.pagination.limit ?? response_data.data.length;
      let offset = offset_increment;
      let promises: Promise<PaginatedResponse<IdNameTagsData>>[] = [];
      while (offset < response_data.pagination.total) {
        promises.push(this.getBotIdsAndNamesAndTags(bot_type, owner_id, undefined, offset).toPromise());
        offset += offset_increment;
      }
      for (const promise of promises) {
        results.push(...(await promise).data);
      }
    }
    return results;
  }

  public getBotsRangeNext<T extends boolInputPar>(
    botType: string,
    pageLimit: number,
    ownerId: string,
    startAfter: string,
    withAssignments: T,
    startAt: string
  ): Observable<PaginatedResponse<returnTypeGetBotWithAssigments<T>>> {
    var botTypeQuery: string;
    var pageLimitQuery: string;
    var ownerIdQuery: string;
    var startAfterQuery: string;
    var withAssignmentsQuery: string = '&assignments=' + withAssignments.toString();
    var startAtQuery: string;

    if (botType == '' || botType == ' ' || botType == 'ALL') {
      botTypeQuery = '?';
    } else {
      botTypeQuery = '?bot_type=' + botType;
    }

    if (pageLimit == 0) {
      pageLimitQuery = '';
    } else {
      pageLimitQuery = '&limit=' + pageLimit;
    }
    if (ownerId == '' || ownerId == ' ') {
      ownerIdQuery = '';
    } else {
      ownerIdQuery = '&owner_id=' + ownerId;
    }

    if (startAfter == '' || startAfter == ' ') {
      startAfterQuery = '';
    } else {
      startAfterQuery = '&start_after=' + startAfter;
    }

    if (startAt == '' || startAt == ' ') {
      startAtQuery = '';
    } else {
      startAtQuery = '&start_at=' + startAt;
    }

    return this.http.get<PaginatedResponse<any>>(
      environment.api.papi + '/bots' + botTypeQuery + pageLimitQuery + ownerIdQuery + startAfterQuery + withAssignmentsQuery + withAssignmentsQuery + startAtQuery
    );
  }
  private getBots(offset?: number): Observable<any> {
    return this.http.get<PaginatedResponse<any>>(environment.api.papi + '/bots?assignments=true&offset=' + offset);
  }

  public async getAllBots(): Promise<any[]> {
    let response_data = await this.getBots(0).toPromise();
    let results: any[] = [];
    results.push(...response_data.data);
    if (response_data.pagination.total > response_data.data.length) {
      const offset_increment = response_data.pagination.limit ?? response_data.data.length;
      let offset = offset_increment;
      let promises: Promise<PaginatedResponse<any>>[] = [];
      while (offset < response_data.pagination.total) {
        promises.push(this.getBots(offset).toPromise());
        offset += offset_increment;
      }
      for (const promise of promises) {
        results.push(...(await promise).data);
      }
    }
    return results;
  }
  private getBotsWithIdAndOwner(offset?: number): Observable<PaginatedResponse<IdOwnerData>> {
    return this.http.get<PaginatedResponse<IdOwnerData>>(environment.api.papi + '/bots?select=owner_id&select=id&offset=' + offset);
  }

  public async getAllBotsWithIdAndOwner(): Promise<IdOwnerData[]> {
    let response_data = await this.getBotsWithIdAndOwner(0).toPromise();
    let results: IdOwnerData[] = [];
    results.push(...response_data.data);
    if (response_data.pagination.total > response_data.data.length) {
      const offset_increment = response_data.pagination.limit ?? response_data.data.length;
      let offset = offset_increment;
      let promises: Promise<PaginatedResponse<any>>[] = [];
      while (offset < response_data.pagination.total) {
        promises.push(this.getBotsWithIdAndOwner(offset).toPromise());
        offset += offset_increment;
      }
      for (const promise of promises) {
        results.push(...(await promise).data);
      }
    }
    return results;
  }
  private getBotsWithIdAndVenue(offset?: number): Observable<any> {
    return this.http.get<PaginatedResponse<any>>(environment.api.papi + '/bots?select=venue_id&select=id&offset=' + offset);
  }

  public async getAllBotsWithIdAndVenue(): Promise<any[]> {
    let response_data = await this.getBotsWithIdAndVenue(0).toPromise();
    let results: any[] = [];
    results.push(...response_data.data);
    if (response_data.pagination.total > response_data.data.length) {
      const offset_increment = response_data.pagination.limit ?? response_data.data.length;
      let offset = offset_increment;
      let promises: Promise<PaginatedResponse<any>>[] = [];
      while (offset < response_data.pagination.total) {
        promises.push(this.getBotsWithIdAndVenue(offset).toPromise());
        offset += offset_increment;
      }
      for (const promise of promises) {
        results.push(...(await promise).data);
      }
    }
    return results;
  }

  public deleteBot(bot_id: string): Observable<any> {
    return this.http.delete(environment.api.papi + '/bots/' + bot_id);
  }
  public deleteScheduler(scheduler_id: string): Observable<any> {
    return this.http.delete(environment.api.papi + '/notifications/schedule/' + scheduler_id);
  }

  public getPbxTemplates(): Observable<any> {
    return this.http.get(environment.api.papi + '/pbx-templates');
  }
  public getPbxRegistrationStatus(pbx_id): Observable<any> {
    return this.http.get(environment.api.papi + '/pbx/' + pbx_id + '/status');
  }

  public getPBXIds(start_after?: string, offset?: number, customer_id?: string): Observable<PaginatedResponse<string>> {
    let endpoint = '/pbx/ids?';
    let prepend = '';
    if (typeof start_after !== 'undefined') {
      endpoint = endpoint + prepend + 'start_after=' + start_after;
      prepend = '&';
    }
    if (typeof offset !== 'undefined') {
      endpoint = endpoint + prepend + 'offset=' + Math.floor(offset).toString();
      prepend = '&';
    }
    if (typeof customer_id !== 'undefined') {
      endpoint = endpoint + prepend + 'customer_id=' + customer_id;
      prepend = '&';
    }
    return this.http.get<PaginatedResponse<string>>(environment.api.papi + endpoint);
  }

  public getTemplateMaps(start_after?: string, offset?: number, customer_id?: string): Observable<PaginatedResponse<string>> {
    let endpoint = '/wam_template_maps?';
    let prepend = '';
    if (typeof start_after !== 'undefined') {
      endpoint = endpoint + prepend + 'start_after=' + start_after;
      prepend = '&';
    }
    if (typeof offset !== 'undefined') {
      endpoint = endpoint + prepend + 'offset=' + Math.floor(offset).toString();
      prepend = '&';
    }
    if (typeof customer_id !== 'undefined') {
      endpoint = endpoint + prepend + 'customer_id=' + customer_id;
      prepend = '&';
    }
    return this.http.get<PaginatedResponse<string>>(environment.api.papi + endpoint);
  }

  public addScheduler(scheduler: any): Observable<any> {
    return this.http.post<any>(environment.api.papi + '/notifications/schedule', scheduler);
  }
  public putScheduler(scheduler: any): Observable<any> {
    return this.http.put<any>(environment.api.papi + '/notifications/schedule/' + scheduler.id, scheduler);
  }
  public getScheduler(id: any): Observable<any> {
    return this.http.get<any>(environment.api.papi + '/notifications/schedule/' + id);
  }

  public getSchedulerIds(start_after?: string, offset?: number, customer_id?: string): Observable<PaginatedResponse<string>> {
    let endpoint = '/notifications/schedule/ids?';
    let prepend = '';
    if (typeof start_after !== 'undefined') {
      endpoint = endpoint + prepend + 'start_after=' + start_after;
      prepend = '&';
    }
    if (typeof offset !== 'undefined') {
      endpoint = endpoint + prepend + 'offset=' + Math.floor(offset).toString();
      prepend = '&';
    }
    if (typeof customer_id !== 'undefined') {
      endpoint = endpoint + prepend + 'customer_id=' + customer_id;
      prepend = '&';
    }
    return this.http.get<PaginatedResponse<string>>(environment.api.papi + endpoint);
  }
  public getSchedulerSummary(start_after?: string, offset?: number, customer_id?: string): Observable<PaginatedResponse<string>> {
    let endpoint = '/notifications/schedule/summary?';
    let prepend = '';
    if (typeof start_after !== 'undefined') {
      endpoint = endpoint + prepend + 'start_after=' + start_after;
      prepend = '&';
    }
    if (typeof offset !== 'undefined') {
      endpoint = endpoint + prepend + 'offset=' + Math.floor(offset).toString();
      prepend = '&';
    }
    if (typeof customer_id !== 'undefined') {
      endpoint = endpoint + prepend + 'customer_id=' + customer_id;
      prepend = '&';
    }
    return this.http.get<PaginatedResponse<string>>(environment.api.papi + endpoint);
  }

  public addPbx(config: any): Observable<any> {
    return this.http.post(environment.api.papi + '/pbx', config);
  }
  public addNumber(config: any): Observable<BooklineNumber> {
    return this.http.post<BooklineNumber>(environment.api.papi + '/numbers', config);
  }
  public putNumber(config: BooklineNumber): Observable<BooklineNumber> {
    return this.http.put<BooklineNumber>(environment.api.papi + '/numbers/' + config.id, config);
  }
  public blockNumber(number_id: string): Observable<any> {
    return this.http.patch(environment.api.papi + '/numbers/' + number_id + '?status=BLOCKED', null);
  }

  public releaseNumber(number_id: string): Observable<any> {
    return this.http.patch(environment.api.papi + '/numbers/' + number_id + '?status=ACTIVE', null);
  }

  public getNumber(number: string): Observable<NumberWithAssignment> {
    return this.http.get<NumberWithAssignment>(environment.api.papi + '/numbers/' + number);
  }

  public getNumbers<T extends boolInputPar = true>(
    status?: NumberStatusType,
    assignments?: T,
    start_after?: string,
    offset?: number,
    customer_id?: string,
    number_type?: NumberTypeType,
    platform?: string
  ): Observable<PaginatedResponse<returnTypeGetNumberWithAssigments<T>>> {
    let endpoint = 'numbers?assignments=';
    if (typeof assignments === 'undefined') {
      endpoint += 'true';
    } else {
      endpoint += assignments.toString();
    }
    if (typeof status !== 'undefined') {
      endpoint = endpoint + '&status=' + status;
    }
    if (typeof start_after !== 'undefined') {
      endpoint = endpoint + '&start_after=' + start_after;
    }
    if (typeof offset !== 'undefined') {
      endpoint = endpoint + '&offset=' + Math.floor(offset).toString();
    }
    if (typeof customer_id !== 'undefined') {
      endpoint = endpoint + '&customer_id=' + customer_id;
    }
    if (typeof number_type !== 'undefined') {
      endpoint = endpoint + '&number_type=' + number_type;
    }
    if (typeof platform !== 'undefined') {
      endpoint = endpoint + '&platform=' + platform;
    }
    return this.http.get<PaginatedResponse<any>>(environment.api.papi + '/' + endpoint);
  }

  public async getAllNumbers(status?: NumberStatusType, number_type?: NumberTypeType, platform?: string, customer_id?: string): Promise<BooklineNumber[]> {
    let response_data = await this.getNumbers(status, false, undefined, undefined, undefined, number_type, platform).toPromise();
    let results: BooklineNumber[] = [];
    results.push(...response_data.data);
    if (response_data.pagination.total > response_data.data.length) {
      const offset_increment = response_data.pagination.limit ?? response_data.data.length;
      let offset = offset_increment;
      let promises: Promise<PaginatedResponse<BooklineNumber>>[] = [];
      while (offset < response_data.pagination.total) {
        promises.push(this.getNumbers(status, false, undefined, offset, undefined, number_type, platform).toPromise());
        offset += offset_increment;
      }
      for (const promise of promises) {
        results.push(...(await promise).data);
      }
    }
    return results;
  }

  public getNumberIds(start_after?: string, offset?: number, customer_id?: string, status?: NumberStatusType, number_type?: NumberTypeType, platform?: string): Observable<PaginatedResponse<string>> {
    let endpoint = '/numbers/ids?';
    let prepend = '';
    if (typeof start_after !== 'undefined') {
      endpoint = endpoint + prepend + 'start_after=' + start_after;
      prepend = '&';
    }
    if (typeof offset !== 'undefined') {
      endpoint = endpoint + prepend + 'offset=' + Math.floor(offset).toString();
      prepend = '&';
    }
    if (typeof customer_id !== 'undefined') {
      endpoint = endpoint + prepend + 'customer_id=' + customer_id;
      prepend = '&';
    }
    if (typeof status !== 'undefined') {
      endpoint = endpoint + prepend + 'status=' + status;
      prepend = '&';
    }
    if (typeof number_type !== 'undefined') {
      endpoint = endpoint + prepend + 'number_type=' + number_type;
      prepend = '&';
    }
    if (typeof platform !== 'undefined') {
      endpoint = endpoint + prepend + 'platform=' + platform;
      prepend = '&';
    }
    return this.http.get<PaginatedResponse<string>>(environment.api.papi + endpoint);
  }

  public async getAllNumberIds(customer_id?: string, status?: NumberStatusType, number_type?: NumberTypeType, platform?: string): Promise<string[]> {
    let response_data = await this.getNumberIds(undefined, undefined, customer_id, status, number_type, platform).toPromise();
    let results: string[] = [];
    results.push(...response_data.data);
    if (response_data.pagination.total > response_data.data.length) {
      const offset_increment = response_data.pagination.limit ?? response_data.data.length;
      let offset = offset_increment;
      let promises: Promise<PaginatedResponse<string>>[] = [];
      while (offset < response_data.pagination.total) {
        promises.push(this.getNumberIds(undefined, offset, customer_id, status, number_type, platform).toPromise());
        offset += offset_increment;
      }
      for (const promise of promises) {
        results.push(...(await promise).data);
      }
    }
    return results;
  }

  public async getAllPBXIds(customer_id?: string): Promise<string[]> {
    let response_data = await this.getPBXIds(undefined, undefined, customer_id).toPromise();
    let results: string[] = [];
    results.push(...response_data.data);
    if (response_data.pagination.total > response_data.data.length) {
      const offset_increment = response_data.pagination.limit ?? response_data.data.length;
      let offset = offset_increment;
      let promises: Promise<PaginatedResponse<string>>[] = [];
      while (offset < response_data.pagination.total) {
        promises.push(this.getPBXIds(undefined, offset, customer_id).toPromise());
        offset += offset_increment;
      }
      for (const promise of promises) {
        results.push(...(await promise).data);
      }
    }
    return results;
  }

  public async getAllSchedulerIds(customer_id?: string): Promise<string[]> {
    let response_data = await this.getSchedulerIds(undefined, undefined, customer_id).toPromise();
    let results: string[] = [];
    results.push(...response_data.data);
    if (response_data.pagination.total > response_data.data.length) {
      const offset_increment = response_data.pagination.limit ?? response_data.data.length;
      let offset = offset_increment;
      let promises: Promise<PaginatedResponse<string>>[] = [];
      while (offset < response_data.pagination.total) {
        promises.push(this.getSchedulerIds(undefined, offset, customer_id).toPromise());
        offset += offset_increment;
      }
      for (const promise of promises) {
        results.push(...(await promise).data);
      }
    }
    return results;
  }
  public async getAllSchedulerSummary(customer_id?: string): Promise<string[]> {
    let response_data = await this.getSchedulerSummary(undefined, undefined, customer_id).toPromise();
    let results: string[] = [];
    results.push(...response_data.data);
    if (response_data.pagination.total > response_data.data.length) {
      const offset_increment = response_data.pagination.limit ?? response_data.data.length;
      let offset = offset_increment;
      let promises: Promise<PaginatedResponse<string>>[] = [];
      while (offset < response_data.pagination.total) {
        promises.push(this.getSchedulerSummary(undefined, offset, customer_id).toPromise());
        offset += offset_increment;
      }
      for (const promise of promises) {
        results.push(...(await promise).data);
      }
    }
    return results;
  }

  public async getAllTemplateMaps(customer_id?: string): Promise<any[]> {
    let response_data = await this.getTemplateMaps(undefined, undefined, customer_id).toPromise();
    let results: string[] = [];
    results.push(...response_data.data);
    if (response_data.pagination.total > response_data.data.length) {
      const offset_increment = response_data.pagination.limit ?? response_data.data.length;
      let offset = offset_increment;
      let promises: Promise<PaginatedResponse<string>>[] = [];
      while (offset < response_data.pagination.total) {
        promises.push(this.getTemplateMaps(undefined, offset, customer_id).toPromise());
        offset += offset_increment;
      }
      for (const promise of promises) {
        results.push(...(await promise).data);
      }
    }
    return results;
  }

  public getNumbersRangeNext<T extends boolInputPar>(
    status: string,
    carrier: string,
    platform: string,
    assignments: T,
    assignment_status: string,
    channel_type: string,
    number_type: string,
    country_code: string,
    start_after: string,
    start_at: string,
    limit: number
  ): Observable<PaginatedResponse<returnTypeGetNumberWithAssigments<T>>> {
    var statusQuery: string;
    var carrierQuery: string;
    var platformQuery: string;
    var assignmentsQuery: string = '&assignments=' + assignments.toString();
    var assignment_statusQuery: string;
    var channel_typeQuery: string;
    var number_typeQuery: string;
    var country_codeQuery: string;
    var start_afterQuery: string;
    var start_atQuery: string;
    var limitQuery: string;

    if (status == '' || status == ' ' || status == 'ALL') {
      statusQuery = '?';
    } else {
      statusQuery = '?status=' + status;
    }

    if (carrier == '' || carrier == ' ') {
      carrierQuery = '';
    } else {
      carrierQuery = '&carrier=' + carrier;
    }
    if (platform == '' || platform == ' ') {
      platformQuery = '';
    } else {
      platformQuery = '&platform=' + platform;
    }

    if (assignment_status == '' || assignment_status == ' ') {
      assignment_statusQuery = '';
    } else {
      assignment_statusQuery = '&assignment_status=' + assignment_status;
    }

    if (channel_type == '' || channel_type == ' ') {
      channel_typeQuery = '';
    } else {
      channel_typeQuery = '&channel_type=' + channel_type;
    }

    if (number_type == '' || number_type == ' ') {
      number_typeQuery = '';
    } else {
      number_typeQuery = '&number_type=' + number_type;
    }

    if (channel_type == '' || channel_type == ' ') {
      channel_typeQuery = '';
    } else {
      channel_typeQuery = '&channel_type=' + channel_type;
    }

    if (country_code == '' || country_code == ' ') {
      country_codeQuery = '';
    } else {
      country_codeQuery = '&country_code=' + country_code;
    }

    if (start_after == '' || start_after == ' ') {
      start_afterQuery = '';
    } else {
      start_afterQuery = '&start_after=' + start_after;
    }

    if (start_at == '' || start_at == ' ') {
      start_atQuery = '';
    } else {
      start_atQuery = '&start_at=' + start_at;
    }

    if (limit == 0 || limit < 0) {
      limitQuery = '';
    } else {
      limitQuery = '&limit=' + limit;
    }

    return this.http.get<PaginatedResponse<returnTypeGetNumberWithAssigments<T>>>(
      environment.api.papi +
        '/numbers' +
        statusQuery +
        carrierQuery +
        platformQuery +
        assignmentsQuery +
        assignment_statusQuery +
        channel_typeQuery +
        number_typeQuery +
        country_codeQuery +
        start_afterQuery +
        start_atQuery +
        limitQuery
    );
  }

  public getCustomers(start_after?: string, offset?: number): Observable<PaginatedResponse<Customer>> {
    let endpoint = '/customers';
    if (typeof start_after !== 'undefined') {
      endpoint = endpoint + '?start_after=' + start_after;
    }
    if (typeof offset !== 'undefined') {
      endpoint = endpoint + '?offset=' + Math.floor(offset).toString();
    }
    return this.http.get<PaginatedResponse<any>>(environment.api.papi + endpoint);
  }
  public getVenues(start_after?: string, offset?: number): Observable<PaginatedResponse<Venue>> {
    let endpoint = '/venues';
    if (typeof start_after !== 'undefined') {
      endpoint = endpoint + '?start_after=' + start_after;
    }
    if (typeof offset !== 'undefined') {
      endpoint = endpoint + '?offset=' + Math.floor(offset).toString();
    }
    return this.http.get<PaginatedResponse<any>>(environment.api.papi + endpoint);
  }

  public getVenueGroups(start_after?: string, offset?: number): Observable<PaginatedResponse<VenueGroup>> {
    let endpoint = '/venue_groups';
    if (typeof start_after !== 'undefined') {
      endpoint = endpoint + '?start_after=' + start_after;
    }
    if (typeof offset !== 'undefined') {
      endpoint = endpoint + '?offset=' + Math.floor(offset).toString();
    }
    return this.http.get<PaginatedResponse<VenueGroup>>(environment.api.papi + endpoint);
  }

  public async getAllCustomers(): Promise<Customer[]> {
    let response_data = await this.getCustomers().toPromise();
    let results: Customer[] = [];
    results.push(...response_data.data);
    if (response_data.pagination.total > response_data.data.length) {
      const offset_increment = response_data.pagination.limit ?? response_data.data.length;
      let offset = offset_increment;
      let promises: Promise<PaginatedResponse<Customer>>[] = [];
      while (offset < response_data.pagination.total) {
        promises.push(this.getCustomers(undefined, offset).toPromise());
        offset += offset_increment;
      }
      for (const promise of promises) {
        results.push(...(await promise).data);
      }
    }
    return results;
  }
  public async getAllVenues(): Promise<Venue[]> {
    let response_data = await this.getVenues().toPromise();
    let results: Venue[] = [];
    results.push(...response_data.data);
    if (response_data.pagination.total > response_data.data.length) {
      const offset_increment = response_data.pagination.limit ?? response_data.data.length;
      let offset = offset_increment;
      let promises: Promise<PaginatedResponse<Venue>>[] = [];
      while (offset < response_data.pagination.total) {
        promises.push(this.getVenues(undefined, offset).toPromise());
        offset += offset_increment;
      }
      for (const promise of promises) {
        results.push(...(await promise).data);
      }
    }
    return results;
  }

  public getCustomerIds(start_after?: string, offset?: number): Observable<PaginatedResponse<string>> {
    let endpoint = '/customers/ids';
    if (typeof start_after !== 'undefined') {
      endpoint = endpoint + '?start_after=' + start_after;
    }
    if (typeof offset !== 'undefined') {
      endpoint = endpoint + '?offset=' + Math.floor(offset).toString();
    }
    return this.http.get<PaginatedResponse<string>>(environment.api.papi + endpoint);
  }

  public async getAllCustomerIds(): Promise<string[]> {
    let response_data = await this.getCustomerIds().toPromise();
    let results: string[] = [];
    results.push(...response_data.data);
    if (response_data.pagination.total > response_data.data.length) {
      const offset_increment = response_data.pagination.limit ?? response_data.data.length;
      let offset = offset_increment;
      let promises: Promise<PaginatedResponse<string>>[] = [];
      while (offset < response_data.pagination.total) {
        promises.push(this.getCustomerIds(undefined, offset).toPromise());
        offset += offset_increment;
      }
      for (const promise of promises) {
        results.push(...(await promise).data);
      }
    }
    return results;
  }

  public getCustomerIdsAndNames(start_after?: string, offset?: number): Observable<PaginatedResponse<IdNameData>> {
    let endpoint = '/customers/ids_names';
    if (typeof start_after !== 'undefined') {
      endpoint = endpoint + '?start_after=' + start_after;
    }
    if (typeof offset !== 'undefined') {
      endpoint = endpoint + '?offset=' + Math.floor(offset).toString();
    }
    return this.http.get<PaginatedResponse<IdNameData>>(environment.api.papi + endpoint);
  }

  public getVenueGroupIdsAndNames(start_after?: string, offset?: number): Observable<PaginatedResponse<VenueGroup>> {
    let endpoint = '/venue-groups';
    if (typeof start_after !== 'undefined') {
      endpoint = endpoint + '?start_after=' + start_after;
    }
    if (typeof offset !== 'undefined') {
      endpoint = endpoint + '?offset=' + Math.floor(offset).toString();
    }
    return this.http.get<PaginatedResponse<VenueGroup>>(environment.api.papi + endpoint);
  }

  public getVenueIdsAndNames(start_after?: string, offset?: number): Observable<PaginatedResponse<Venue>> {
    let endpoint = '/venues';
    if (typeof start_after !== 'undefined') {
      endpoint = endpoint + '?start_after=' + start_after;
    }
    if (typeof offset !== 'undefined') {
      endpoint = endpoint + '?offset=' + Math.floor(offset).toString();
    }
    return this.http.get<PaginatedResponse<Venue>>(environment.api.papi + endpoint);
  }

  public async getAllCustomerIdsAndNames(): Promise<IdNameData[]> {
    let response_data = await this.getCustomerIdsAndNames().toPromise();
    let results: IdNameData[] = [];
    results.push(...response_data.data);
    if (response_data.pagination.total > response_data.data.length) {
      const offset_increment = response_data.pagination.limit ?? response_data.data.length;
      let offset = offset_increment;
      let promises: Promise<PaginatedResponse<IdNameData>>[] = [];
      while (offset < response_data.pagination.total) {
        promises.push(this.getCustomerIdsAndNames(undefined, offset).toPromise());
        offset += offset_increment;
      }
      for (const promise of promises) {
        results.push(...(await promise).data);
      }
    }
    return results;
  }

  public async getAllVenueGroupIdsAndNames(): Promise<VenueGroup[]> {
    let response_data = await this.getVenueGroupIdsAndNames().toPromise();
    let results: VenueGroup[] = [];
    results.push(...response_data.data);
    if (response_data.pagination.total > response_data.data.length) {
      const offset_increment = response_data.pagination.limit ?? response_data.data.length;
      let offset = offset_increment;
      let promises: Promise<PaginatedResponse<VenueGroup>>[] = [];
      while (offset < response_data.pagination.total) {
        promises.push(this.getVenueGroupIdsAndNames(undefined, offset).toPromise());
        offset += offset_increment;
      }
      for (const promise of promises) {
        results.push(...(await promise).data);
      }
    }
    return results;
  }

  public async getAllVenueIdsAndNames(): Promise<Venue[]> {
    let response_data = await this.getVenueIdsAndNames().toPromise();
    let results: Venue[] = [];
    results.push(...response_data.data);
    if (response_data.pagination.total > response_data.data.length) {
      const offset_increment = response_data.pagination.limit ?? response_data.data.length;
      let offset = offset_increment;
      let promises: Promise<PaginatedResponse<Venue>>[] = [];
      while (offset < response_data.pagination.total) {
        promises.push(this.getVenueIdsAndNames(undefined, offset).toPromise());
        offset += offset_increment;
      }
      for (const promise of promises) {
        results.push(...(await promise).data);
      }
    }
    return results;
  }

  public getCustomer(customer: string): Observable<Customer> {
    return this.http.get<Customer>(environment.api.papi + '/customers/' + customer);
  }
  public getVenue(venue: string): Observable<Venue> {
    return this.http.get<Venue>(environment.api.papi + '/venues/' + venue);
  }
  public getVenueGroup(venueGroupId: string): Observable<VenueGroup> {
    return this.http.get<VenueGroup>(environment.api.papi + '/venue-groups/' + venueGroupId);
  }

  public getCustomerIntegrations(customer: string): Observable<PaginatedResponse<Integration>> {
    return this.http.get<PaginatedResponse<Integration>>(environment.api.papi + '/customers/' + customer + '/integrations');
  }

  public addCustomer(customer): Observable<any> {
    return this.http.post<Customer>(environment.api.papi + '/customers/' + customer.id, customer);
  }

  public putCustomer(customer): Observable<any> {
    return this.http.put(environment.api.papi + '/customers/' + customer.id, customer);
  }
  public patchCustomer(customer): Observable<any> {
    return this.http.patch(environment.api.papi + '/customers/' + customer.id, customer);
  }
  public deleteCustomer(customerID): Observable<any> {
    return this.http.delete(environment.api.papi + '/customers/' + customerID);
  }

  public getUsers(): Observable<PaginatedResponse<any>> {
    return this.http.get<PaginatedResponse<any>>(environment.api.papi + '/users');
  }
  public getUsersIdsAndNames(): Observable<PaginatedResponse<any>> {
    return this.http.get<PaginatedResponse<any>>(environment.api.papi + '/users/ids_names');
  }
  public getUser(user: string): Observable<any> {
    user;
    return this.http.get(environment.api.papi + '/users/' + user);
  }

  public addUser(user): Observable<any> {
    return this.http.post(environment.api.papi + '/users/' + user.id, user);
  }

  public putUser(user): Observable<any> {
    return this.http.put(environment.api.papi + '/users/' + user.id, user);
  }
  public patchUser(user): Observable<any> {
    return this.http.patch(environment.api.papi + '/users/' + user.id, user);
  }
  public deleteUser(userID): Observable<any> {
    return this.http.delete(environment.api.papi + '/users/' + userID);
  }
  public getNotificationsBySource(source?: string): Observable<PaginatedResponse<NotificationScheduleConfig>> {
    let endpoint = '/notifications?source_id=';

    return this.http.get<PaginatedResponse<NotificationScheduleConfig>>(environment.api.papi + endpoint + source);
  }
  public getNotificationSchedules(offset?: number): Observable<PaginatedResponse<NotificationScheduleConfig>> {
    let endpoint = '/notifications/schedule';
    if (typeof offset !== 'undefined') {
      endpoint = endpoint + '?offset=' + offset;
    }
    return this.http.get<PaginatedResponse<NotificationScheduleConfig>>(environment.api.papi + endpoint);
  }
  public async getAllNotificationSchedules(): Promise<NotificationScheduleConfig[]> {
    let response_data = await this.getNotificationSchedules().toPromise();
    let results: NotificationScheduleConfig[] = [];
    results.push(...response_data.data);
    if (response_data.pagination.total > response_data.data.length) {
      const offset_increment = response_data.pagination.limit ?? response_data.data.length;
      let offset = offset_increment;
      let promises: Promise<PaginatedResponse<NotificationScheduleConfig>>[] = [];
      while (offset < response_data.pagination.total) {
        promises.push(this.getNotificationSchedules(offset).toPromise());
        offset += offset_increment;
      }
      for (const promise of promises) {
        results.push(...(await promise).data);
      }
    }
    return results;
  }
  public getNotificationSchedulersSummary(offset?: number): Observable<PaginatedResponse<NotificationScheduleConfigSummary>> {
    let endpoint = '/notifications/schedule/summary';
    if (typeof offset !== 'undefined') {
      endpoint = endpoint + '?offset=' + offset;
    }
    return this.http.get<PaginatedResponse<NotificationScheduleConfigSummary>>(environment.api.papi + endpoint);
  }
  public async getAllNotificationSchedulersSummary(): Promise<NotificationScheduleConfigSummary[]> {
    let response_data = await this.getNotificationSchedulersSummary().toPromise();
    let results: NotificationScheduleConfigSummary[] = [];
    results.push(...response_data.data);
    if (response_data.pagination.total > response_data.data.length) {
      const offset_increment = response_data.pagination.limit ?? response_data.data.length;
      let offset = offset_increment;
      let promises: Promise<PaginatedResponse<NotificationScheduleConfigSummary>>[] = [];
      while (offset < response_data.pagination.total) {
        promises.push(this.getNotificationSchedulersSummary(offset).toPromise());
        offset += offset_increment;
      }
      for (const promise of promises) {
        results.push(...(await promise).data);
      }
    }
    return results;
  }
  public getNotificationRun(scheduleId: string, runId: string): Observable<NotificationRun> {
    let endpoint = '/notifications/schedule/' + scheduleId + '/runs/' + runId;
    return this.http.get<NotificationRun>(environment.api.papi + endpoint);
  }
  public getNotificationRuns(scheduleId: string, offset?: number): Observable<PaginatedResponse<NotificationRun>> {
    let endpoint = '/notifications/schedule/' + scheduleId + '/runs';
    if (typeof offset !== 'undefined') {
      endpoint = endpoint + '?offset=' + offset;
    }
    return this.http.get<PaginatedResponse<NotificationRun>>(environment.api.papi + endpoint);
  }
  public async getAllNotificationRuns(scheduleId: string): Promise<NotificationRun[]> {
    let response_data = await this.getNotificationRuns(scheduleId).toPromise();
    let results: NotificationRun[] = [];
    results.push(...response_data.data);
    if (response_data.pagination.total > response_data.data.length) {
      const offset_increment = response_data.pagination.limit ?? response_data.data.length;
      let offset = offset_increment;
      let promises: Promise<PaginatedResponse<NotificationRun>>[] = [];
      while (offset < response_data.pagination.total) {
        promises.push(this.getNotificationRuns(scheduleId, offset).toPromise());
        offset += offset_increment;
      }
      for (const promise of promises) {
        results.push(...(await promise).data);
      }
    }
    return results;
  }
  public getNotificationRunsSummary(scheduleId: string, offset?: number): Observable<PaginatedResponse<NotificationRunSummary>> {
    let endpoint = '/notifications/schedule/' + scheduleId + '/runs/summary';
    if (typeof offset !== 'undefined') {
      endpoint = endpoint + '?offset=' + offset;
    }
    return this.http.get<PaginatedResponse<NotificationRunSummary>>(environment.api.papi + endpoint);
  }
  public async getAllNotificationRunsSummary(scheduleId: string): Promise<NotificationRunSummary[]> {
    let response_data = await this.getNotificationRunsSummary(scheduleId).toPromise();
    let results: NotificationRunSummary[] = [];
    results.push(...response_data.data);
    if (response_data.pagination.total > response_data.data.length) {
      const offset_increment = response_data.pagination.limit ?? response_data.data.length;
      let offset = offset_increment;
      let promises: Promise<PaginatedResponse<NotificationRunSummary>>[] = [];
      while (offset < response_data.pagination.total) {
        promises.push(this.getNotificationRunsSummary(scheduleId, offset).toPromise());
        offset += offset_increment;
      }
      for (const promise of promises) {
        results.push(...(await promise).data);
      }
    }
    return results;
  }
  public getNotificationSequence(seqId: string): Observable<any> {
    return this.http.get(environment.api.papi + '/notifications/sequences/' + seqId);
  }
  public getNotificationSequences(offset?: number): Observable<PaginatedResponse<Sequence>> {
    let endpoint = '/notifications/sequences';
    if (typeof offset !== 'undefined') {
      endpoint = endpoint + '?offset=' + offset;
    }
    return this.http.get<PaginatedResponse<Sequence>>(environment.api.papi + endpoint);
  }
  public async getAllNotificationSequences(): Promise<Sequence[]> {
    let response_data = await this.getNotificationSequences().toPromise();
    let results: Sequence[] = [];
    results.push(...response_data.data);
    if (response_data.pagination.total > response_data.data.length) {
      const offset_increment = response_data.pagination.limit ?? response_data.data.length;
      let offset = offset_increment;
      let promises: Promise<PaginatedResponse<Sequence>>[] = [];
      while (offset < response_data.pagination.total) {
        promises.push(this.getNotificationSequences(offset).toPromise());
        offset += offset_increment;
      }
      for (const promise of promises) {
        results.push(...(await promise).data);
      }
    }
    return results;
  }
  public getNotificationSequencesByParentRun(parent_run_id: string, offset?: number): Observable<PaginatedResponse<Sequence>> {
    let endpoint = '/notifications/sequences?parent_run_id=' + parent_run_id;
    if (typeof offset !== 'undefined') {
      endpoint = endpoint + '&offset=' + offset;
    }
    return this.http.get<PaginatedResponse<Sequence>>(environment.api.papi + endpoint);
  }
  public async getAllNotificationSequencesByParentRun(parent_run_id: string): Promise<Sequence[]> {
    let offset = 0;
    let response_data = await this.getNotificationSequencesByParentRun(parent_run_id, offset).toPromise();
    let results: Sequence[] = [];
    results.push(...response_data.data);
    while (offset + response_data.pagination.limit < response_data.pagination.total) {
      offset = offset + response_data.pagination.limit;
      response_data = await this.getNotificationSequencesByParentRun(parent_run_id, offset).toPromise();
      results.push(...response_data.data);
    }
    return results;
  }

  public getNotification(id: string): Observable<any> {
    return this.http.get(environment.api.papi + '/notifications/' + id);
  }

  public getCustomerAccounts(customer_id: string, offset?: number): Observable<PaginatedResponse<Account>> {
    let endpoint = '/customers/' + customer_id + '/accounts';
    if (typeof offset !== 'undefined') {
      endpoint = endpoint + '?offset=' + offset;
    }
    return this.http.get<PaginatedResponse<Account>>(environment.api.papi + endpoint);
  }
  public async getAllCustomerAccounts(customer_id: string): Promise<Account[]> {
    let response_data = await this.getCustomerAccounts(customer_id).toPromise();
    let results: Account[] = [];
    results.push(...response_data.data);
    if (response_data.pagination.total > response_data.data.length) {
      const offset_increment = response_data.pagination.limit ?? response_data.data.length;
      let offset = offset_increment;
      let promises: Promise<PaginatedResponse<Account>>[] = [];
      while (offset < response_data.pagination.total) {
        promises.push(this.getCustomerAccounts(customer_id, offset).toPromise());
        offset += offset_increment;
      }
      for (const promise of promises) {
        results.push(...(await promise).data);
      }
    }
    return results;
  }

  public getCustomerAccount(customer_id: string, account_id: string): Observable<Account> {
    let endpoint = '/customers/' + customer_id + '/accounts/' + account_id;
    return this.http.get<Account>(environment.api.papi + endpoint);
  }

  public postCustomerAccount(customer_id: string, account: Account): Observable<HttpResponse<any>> {
    let endpoint = '/customers/' + customer_id + '/accounts';
    return this.http.post<any>(environment.api.papi + endpoint, account, {observe: 'response'});
  }
  public deleteCustomerAccount(customer_id: string, account_id: string): Observable<HttpResponse<any>> {
    let endpoint = '/customers/' + customer_id + '/accounts/' + account_id;
    return this.http.delete<any>(environment.api.papi + endpoint, {observe: 'response'});
  }

  public putCustomerAccount(customer_id: string, account: Account): Observable<HttpResponse<any>> {
    let endpoint = '/customers/' + customer_id + '/accounts/' + account.id;
    return this.http.put<any>(environment.api.papi + endpoint, account, {observe: 'response'});
  }

  public getAccounts(offset?: number, filter_id?: string): Observable<PaginatedResponse<AccountData>> {
    let endpoint = '/accounts?';
    if (typeof offset !== 'undefined') {
      endpoint = endpoint + '&offset=' + offset;
    }
    if (typeof filter_id !== 'undefined') {
      endpoint = endpoint + '&id=' + filter_id;
    }
    return this.http.get<PaginatedResponse<AccountData>>(environment.api.papi + endpoint);
  }
  public async getAllAccounts(filter_id?: string): Promise<AccountData[]> {
    let response_data = await this.getAccounts(undefined, filter_id).toPromise();
    let results: AccountData[] = [];
    results.push(...response_data.data);
    if (response_data.pagination.total > response_data.data.length) {
      const offset_increment = response_data.pagination.limit ?? response_data.data.length;
      let offset = offset_increment;
      let promises: Promise<PaginatedResponse<AccountData>>[] = [];
      while (offset < response_data.pagination.total) {
        promises.push(this.getAccounts(offset, filter_id).toPromise());
        offset += offset_increment;
      }
      for (const promise of promises) {
        results.push(...(await promise).data);
      }
    }
    return results;
  }

  public genToken(id): Observable<any> {
    return this.http.post(environment.api.papi + '/tokens', {
      data: id,
      ttl_minutes: 1440,
    });
  }
  public sendMail(link, email, template): Observable<any> {
    return this.http.post(environment.api.papi + '/emails/new', {
      template_id: template,
      dynamic_template_data: {
        LINK: link,
      },
      from_email: 'no-reply@bookline.io',
      to_emails: [email],
    });
  }
  public addAudio(payload): Observable<any> {
    return this.http.post(environment.api.papi + '/audios', payload);
  }

  public getAudio(id: string): Observable<any> {
    return this.http.get<any>(environment.api.papi + '/audios/' + id);
  }
  public getAuditByBotId(id: string): Observable<any> {
    return this.http.get<any>(environment.api.papi + '/bots/' + id + '/audit');
  }
  public getAuditByAssignment(assignment_id: string): Observable<any> {
    return this.http.get<any>(environment.api.papi + '/assignments/' + assignment_id + '/audit');
  }

  //  This endpoint is not done yet, probably the route will be different
  public sendMeetingNotifications(data: any): Observable<any> {
    return this.http.post<any>(environment.api.papi + '/send-notifications/', data);
  }

  public getOpenApi(): Observable<any> {
    return this.http.get<any>(environment.api.papi + '/openapi.json', {responseType: 'json'});
  }

  public getConfigOptions(item_id: configOptionsItemsType): Observable<HttpResponse<ConfigOptionsResponse>> {
    return this.http.get<any>(environment.api.papi + '/config-options/' + item_id, {observe: 'response'});
  }

  public sendWhatsappNotification(data: any, cdr_number: string): Observable<any> {
    return this.http.post<any>(environment.api.papi + '/whatsapp-cdr/' + cdr_number + '/sessions/outbound', data);
  }
}
