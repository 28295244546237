import {Component, Input, OnInit} from '@angular/core';
import {ControlContainer, FormGroup, FormGroupDirective} from '@angular/forms';
import {NbDialogService} from '@nebular/theme';
import {AmazonTTSConfig, TTSConfig, TTSProviders, TTSProvidersType} from '../../../@core/api/platform.service';
import {LanguageEnum} from '../../../@core/data/model-utils.model';
import {AMAZON_VOICES, ELEVENLABS_VOICES, GOOGLE_VOICES, MICROSOFT_VOICES, VOLUME_OPTIONS} from './tts-config-options';

// https://stackoverflow.com/questions/52893088/forwarding-formcontrolname-to-inner-component-in-angular-with-reactive-forms
@Component({
  selector: 'ngx-tts-editor',
  styleUrls: ['./tts-editor.component.scss'],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    },
  ],
  templateUrl: './tts-editor.component.html',
})
export class TTSEditorComponent implements OnInit {
  @Input() formGroup: FormGroup;
  @Input() language: LanguageEnum;
  @Input() displayVertical: boolean;
  @Input() displayLabels: boolean;

  form: FormGroup;
  volumeOptions: string[];
  constructor(private dialogService: NbDialogService, public controlContainer: ControlContainer) {}

  ngOnInit() {
    if (this.formGroup != null) {
      this.form = this.formGroup;
    } else {
      this.form = <FormGroup>this.controlContainer.control;
    }
    this.volumeOptions = [...VOLUME_OPTIONS];
    if (this.displayVertical == null) {
      this.displayVertical = false;
    }
    if (this.displayLabels == null) {
      this.displayLabels = false;
    }
  }

  /** Empty voice title control if new provider does not include the older voice */
  providerChange(): void {
    switch (this.TTSProvider) {
      case 'AMAZON':
        if (Object.keys(AMAZON_VOICES[this.language]).includes(this.selectedVoiceTitle)) {
          return;
        }
        break;
      case 'ELEVENLABS':
        if (ELEVENLABS_VOICES[this.language].includes(this.selectedVoiceTitle)) {
          return;
        }
        break;
      case 'MICROSOFT':
        if (MICROSOFT_VOICES[this.language].includes(this.selectedVoiceTitle)) {
          return;
        }
        break;
      case 'GOOGLE':
        if (GOOGLE_VOICES[this.language].includes(this.selectedVoiceTitle)) {
          return;
        }
        break;
    }
    this.form.get('voice_title').setValue('');
    return;
  }

  get availableProvidersForLanguage(): TTSProvidersType[] {
    const availableProviders: TTSProvidersType[] = [];
    for (const provider of TTSProviders) {
      switch (provider) {
        case 'AMAZON':
          if (Object.keys(AMAZON_VOICES).includes(this.language)) {
            availableProviders.push(provider);
          }
          break;
        case 'ELEVENLABS':
          if (Object.keys(ELEVENLABS_VOICES).includes(this.language)) {
            availableProviders.push(provider);
          }
          break;
        case 'MICROSOFT':
          if (Object.keys(MICROSOFT_VOICES).includes(this.language)) {
            availableProviders.push(provider);
          }
          break;
        case 'GOOGLE':
          if (Object.keys(GOOGLE_VOICES).includes(this.language)) {
            availableProviders.push(provider);
          }
          break;
      }
    }
    return availableProviders;
  }

  get availableVoicesForProviderAndLanguage(): string[] {
    switch (this.TTSProvider) {
      case 'AMAZON':
        if (Object.keys(AMAZON_VOICES).includes(this.language)) {
          return Object.keys(AMAZON_VOICES[this.language]);
        }
        break;
      case 'ELEVENLABS':
        if (Object.keys(ELEVENLABS_VOICES).includes(this.language)) {
          return ELEVENLABS_VOICES[this.language];
        }
        break;
      case 'MICROSOFT':
        if (Object.keys(MICROSOFT_VOICES).includes(this.language)) {
          return MICROSOFT_VOICES[this.language];
        }
        break;
      case 'GOOGLE':
        if (Object.keys(GOOGLE_VOICES).includes(this.language)) {
          return GOOGLE_VOICES[this.language];
        }
        break;
    }
    return [];
  }

  get formValue(): AmazonTTSConfig | TTSConfig {
    return this.form.value;
  }

  get TTSProvider(): TTSProvidersType {
    return this.formValue.provider;
  }

  get selectedVoiceTitle(): string {
    return this.formValue.voice_title;
  }

  get isElevenlabs(): boolean {
    return this.TTSProvider == 'ELEVENLABS';
  }

  get isAmazon(): boolean {
    return this.TTSProvider == 'AMAZON';
  }
}
