import {Component, Input, OnInit, OnDestroy, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {NbDialogRef} from '@nebular/theme';
import {NbToastrService} from '@nebular/theme';
import {HttpErrorResponse} from '@angular/common/http';

import {Customer, PlatformService} from '../../../@core/api/platform.service';
import {defaultCustomer, profileToFormGroup, extractSalesforceIdFromUrl, profileFromFormGroup} from './utils';

@Component({
  selector: 'ngx-new-customer-dialog',
  templateUrl: 'new-customer-dialog.component.html',
  styleUrls: ['new-customer-dialog.component.scss'],
})
export class NewCustomerDialogComponent implements OnInit, OnDestroy {
  @Input() tenant_id: string;
  @Input() owner_id: string;
  @Input() override_timezone?: string;
  @ViewChild('autoInput') customerInput;
  constructor(protected ref: NbDialogRef<NewCustomerDialogComponent>, private fb: FormBuilder, private toastrService: NbToastrService, private papi: PlatformService) {}

  pattern = '([01]?[0-9]|2[0123]):([012345][0-9])';
  createMode: boolean = false;
  expertMode: boolean = false;
  submitting: boolean = false;
  customer: Customer;
  mainForm: FormGroup;
  scheduleForm: FormGroup;

  async ngOnInit() {
    this.customer = defaultCustomer();
    this.mainForm = profileToFormGroup(this.customer, this.fb);

    this.mainForm.get('crm_id')?.valueChanges.subscribe((value) => {
      this.mainForm.get('crm_id')?.setValue(extractSalesforceIdFromUrl(value), {emitEvent: false});
    });
  }

  async save() {
    const profile = profileFromFormGroup(this.mainForm);
    // TODO sanity checks
    this.submitting = true;
    let message = 'Customer created!';
    try {
      const response = await this.papi.putCustomer(profile).toPromise();
      if (response.status === 201) {
        message = 'Customer created!';
      }
    } catch (error: any) {
      if (error instanceof HttpErrorResponse) {
        this.toastrService.danger('Status: ' + error.status + ' message: ' + error.message, 'Unknown error creating customer.');
        return;
      } else {
        this.toastrService.danger(error, 'Unknown error updating customer.');
        return;
      }
    } finally {
      this.submitting = false;
    }
    this.toastrService.success(message, 'Success!');
    this.ref.close({
      success: true,
      data: profile,
    });
  }

  dismiss() {
    this.ref.close({
      success: true,
      data: null,
    });
  }

  ngOnDestroy() {}
}
