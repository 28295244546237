import {FormArray, FormBuilder, FormGroup} from '@angular/forms';
import {Venue} from '../../../@core/api/platform.service';
import * as uuid from 'uuid';

export function defaultVenue(): Venue {
  return {
    id: uuid.v4(),
    name: '',
    crm_id: '',
    customer_id: '',
    type: 'RESTAURANT',
  };
}

export function profileToFormGroup(profile: Venue, formBuilder: FormBuilder): FormGroup {
  const formGroup = formBuilder.group(profile);
  return formGroup;
}

export function profileFromFormGroup(profileFormGroup: FormGroup): Venue {
  const newVenue = {
    id: profileFormGroup.get('id').value,
    name: profileFormGroup.get('name').value,
    crm_id: profileFormGroup.get('crm_id').value,
    customer_id: profileFormGroup.get('customer_id').value,
    type: profileFormGroup.get('type').value,
  };
  if (newVenue.id == null || newVenue.id.trim() == '') {
    throw {message: 'Invalid Venue ID'};
  } else if (newVenue.name == null || newVenue.name.trim() == '') {
    throw {message: 'Invalid Venue Name'};
  } else if (newVenue.customer_id == null || newVenue.customer_id.trim() == '') {
    throw {message: 'Invalid Customer ID'};
  } else if (newVenue.type == null || newVenue.type.trim() == '') {
    throw {message: 'Invalid Venue Type'};
  }
  return newVenue;
}

export function extractSalesforceIdFromUrl(url: string): string {
  const regex = /https:\/\/booklinesolutions\.lightning\.force\.com\/lightning\/r\/Account\/(.*?)\/view/;
  const match = url.match(regex);
  if (match && match[1]) {
    return match[1];
  } else {
    return url;
  }
}
