import {Component, Input, OnInit} from '@angular/core';
import {ControlContainer, FormGroup, FormGroupDirective} from '@angular/forms';
import {NbDialogService} from '@nebular/theme';
import {STTConfig, STTProviders, STTProvidersType} from '../../../@core/api/platform.service';
import {LanguageEnum} from '../../../@core/data/model-utils.model';

// https://stackoverflow.com/questions/52893088/forwarding-formcontrolname-to-inner-component-in-angular-with-reactive-forms
@Component({
  selector: 'ngx-stt-editor',
  styleUrls: ['./stt-editor.component.scss'],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    },
  ],
  templateUrl: './stt-editor.component.html',
})
export class STTEditorComponent implements OnInit {
  @Input() formGroup: FormGroup;
  @Input() language: LanguageEnum;
  @Input() displayVertical: boolean;
  @Input() displayLabels: boolean;

  form: FormGroup;
  volumeOptions: string[];
  constructor(private dialogService: NbDialogService, public controlContainer: ControlContainer) {}

  ngOnInit() {
    if (this.formGroup != null) {
      this.form = this.formGroup;
    } else {
      this.form = <FormGroup>this.controlContainer.control;
    }
    if (this.displayVertical == null) {
      this.displayVertical = false;
    }
    if (this.displayLabels == null) {
      this.displayLabels = false;
    }
  }

  /** Currently, do nothing */
  providerChange(): void {
    return;
  }

  get availableProvidersForLanguage(): STTProvidersType[] {
    const availableProviders: STTProvidersType[] = [];
    for (const provider of STTProviders) {
      availableProviders.push(provider);
    }
    return availableProviders;
  }

  get formValue(): STTConfig {
    return this.form.value;
  }

  get STTProvider(): STTProvidersType {
    return this.formValue.provider;
  }
}
