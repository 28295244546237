import {RouterModule} from '@angular/router';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ThemeModule} from '../../@theme/theme.module';
import {NbAuthModule} from '@nebular/auth';

import {LoginComponent} from './login/login.component';
import {RegisterComponent} from './register/register.component';
import {UserComponent} from './user/user.component';
import {NbCheckboxModule, NbButtonModule, NbIconModule, NbInputModule, NbCardModule} from '@nebular/theme';
import {NbEvaIconsModule} from '@nebular/eva-icons';

@NgModule({
  imports: [FormsModule, ReactiveFormsModule, ThemeModule, RouterModule, NbAuthModule, NbCardModule, NbInputModule, NbCheckboxModule, NbButtonModule, NbIconModule, NbEvaIconsModule],
  declarations: [LoginComponent, RegisterComponent, UserComponent],
  entryComponents: [],
})
export class AuthModule {}
