import {FormArray, FormBuilder, FormGroup} from '@angular/forms';
import { Customer } from '../../../@core/api/platform.service';
import * as uuid from 'uuid';

export function defaultCustomer(): Customer {
  return {
    id:  uuid.v4(),
    name: '',
    crm_id: '',
    accounts: [],
    integrations: [],
  };
}

export function profileToFormGroup(profile: Customer, formBuilder: FormBuilder): FormGroup {
  const formGroup = formBuilder.group(profile);
  return formGroup;
}

export function profileFromFormGroup(profileFormGroup: FormGroup): Customer {
  return {
    id: profileFormGroup.get('id').value,
    name: profileFormGroup.get('name').value,
    crm_id: profileFormGroup.get('crm_id').value,
    accounts: [],
    integrations: [],
  };
}

export function extractSalesforceIdFromUrl(url: string): string {
  const regex = /https:\/\/booklinesolutions\.lightning\.force\.com\/lightning\/r\/Account\/(.*?)\/view/;
  const match = url.match(regex);
  if (match && match[1]) {
    return match[1];
  } else {
    return url;
  }
}
