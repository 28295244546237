import {Component, Input, OnInit, OnDestroy, Pipe, PipeTransform, ViewChild, ElementRef} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
// import * as fs from "fs";
import {Subscription} from 'rxjs';
// import {take} from "rxjs/operators";
import {ProxyService} from '../../../../@core/api/proxy.service';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
// import {saveAs} from "file-saver";
import {createFFmpeg, fetchFile} from '@ffmpeg/ffmpeg';
import {NbDialogRef, NbDialogService} from '@nebular/theme';
import {NbToastrService} from '@nebular/theme';

const ffmpeg = createFFmpeg({log: true});

import * as firebase from 'firebase/app';
import 'firebase/storage';
import {UserService} from '../../../../@core/firestore/user.service';
import {ConfirmDeleteComponent} from '../../../../portal/restaurant-bots/confirm-delete/confirm-delete.component';
import {ClientService} from '../../../../@core/firestore/clients.service';
import {SupportService} from '../../../../@core/api/support.service';
import {PlatformService} from '../../../../@core/api/platform.service';
import {ImportComponent} from '../../../../portal/support/import-component/import-url.component';

const voiceOptions = require('../../../../portal/utils/voice-options.json');

@Component({
  selector: 'ngx-audio-generator-dialog',
  templateUrl: 'audio-generator-dialog.component.html',
  styleUrls: ['audio-generator-dialog.component.scss'],
})
export class AudioGeneratorDialogComponent implements OnInit, OnDestroy {
  @Input() title: string;
  @Input() supported_languages: string[];
  @Input() value: string;

  constructor(
    protected ref: NbDialogRef<AudioGeneratorDialogComponent>,
    private fb: FormBuilder,
    private cs: ClientService,
    private service: SupportService,
    private ps: ProxyService,
    private userService: UserService,
    private dialogService: NbDialogService,
    private papi: PlatformService
  ) {}

  form: FormGroup;
  booklineForm: FormGroup;

  musicDurationSeconds;
  silentSeconds = '';
  joinedDuration = '';
  wantMerge = false;
  loadingMerge = false;
  uploadLoading = false;
  musicShareLink = '';

  storage: any;
  musicBlob: Blob = null;
  audioWithMusicURL = '';
  musicVolume = 1;

  toolsArray: any = [];
  initialPause = 0;
  betweenPause = 0;
  linkBoolJoined = true;
  linkBoolMerged = true;
  showFullLinkJoined = false;
  showFullLinkMerged = false;
  customFileNameJoined = '';
  customFileNameMerged = '';
  joinedBlob = null;
  mergedBlob = null;
  shareLinkJoined = '';
  shareLinkMerged = '';
  languages = [];
  genders = [];
  providers = [];
  joinedURL = '';
  audioURL: any;

  dialog_subs: Subscription;
  papi_subs: Subscription;
  papi_subs1: Subscription;
  cs_subs: Subscription;
  us_subs: Subscription;
  resultSubs: Subscription;
  googleSubs: Subscription;
  microsoftSubs: Subscription;
  currentUser: firebase.User;
  linkBool: Boolean = true;
  showFullLink: Boolean = false;

  finalURL = '';
  // customFileName: string = "";

  ngOnInit() {
    this.storage = firebase.storage();

    this.userService
      .getCurrentUser()
      .then((user) => {
        this.currentUser = user;
      })
      .catch((err) => console.log(err));

    let providersUtil = [];
    let laguagesUtil = [];
    let genderUtil = [];
    voiceOptions.tts.forEach((element) => {
      providersUtil.push(element.provider);
      laguagesUtil.push(element.language);
      genderUtil.push(element.gender);
    });
    this.languages = [...new Set(laguagesUtil)];
    this.providers = [...new Set(providersUtil)];
    this.genders = [...new Set(genderUtil)];

    this.toolsArray.push({
      languages: this.languages,
      providers: this.providers,
      genders: this.genders,
      customFileName: '',
      shareLink: '',
      ttsText: '',
      lang: '',
      gender: '',
      provider: '',
      voice: null,
      audioURL: '',
      blob: null,
      customVolume: 1,
    });
  }

  delete(index) {
    this.dialog_subs = this.dialogService.open(ConfirmDeleteComponent, {autoFocus: false}).onClose.subscribe((action) => {
      if (action) {
        this.toolsArray.splice(index, 1);
      }
      this.dialog_subs.unsubscribe();
    });
  }

  onImport() {
    this.dialog_subs = this.dialogService.open(ImportComponent, {autoFocus: false}).onClose.subscribe((url) => {
      if (url) {
        // DO THE MAGIC
        this.papi_subs = this.papi.getAudio(this.getIdFromURL(url)).subscribe((resp) => {
          console.log('AUDIO METADATA', resp);
          const toolsUtil = resp.tracks.map((track) => {
            return {
              languages: this.languages,
              providers: this.providers,
              genders: this.genders,
              customFileName: '',
              shareLink: '',
              ttsText: track.text,
              lang: '',
              gender: '',
              provider: '',
              voice: voiceOptions.tts.filter((voi) => voi.code === track.voice)[0],
              audioURL: '',
              blob: null,
              customVolume: track.volume,
            };
          });
          this.toolsArray = toolsUtil.slice();
          this.initialPause = resp.initial_pause;
          this.betweenPause = resp.between_pause;
          if (resp.background_track.url !== '') {
            this.wantMerge = true;
            this.musicShareLink = resp.background_track.url;
            this.musicVolume = resp.background_track.volume;
          }
          console.log('toolsArray en import', this.toolsArray);
        });

        console.log('URL >>>', url);
      }
      this.dialog_subs.unsubscribe();
    });
  }
  // audio file
  handleFileInput(event: any) {
    const file: File = event.target.files[0];
    this.createAudioBlobFromFile(file);
  }

  // any file

  checkCreateSynth(index?) {
    if (index === null) {
      for (const obj of this.toolsArray) {
        if (obj.ttsText === '' || obj.voice === null) {
          return true;
        }
      }
      return false;
    }
    return this.toolsArray[index].ttsText === '' || this.toolsArray[index].voice === null;
  }

  createAudioBlobFromFile(file: File) {
    this.loadingMerge = true;
    if (file) {
      const reader = new FileReader();
      reader.onloadend = async () => {
        this.musicBlob = new Blob([reader.result as ArrayBuffer], {type: file.type});

        const storageRef = this.storage.ref(this.currentUser.email + '/' + file.name);

        // Upload a file
        await storageRef
          .put(this.musicBlob)
          .then(async (res) => {
            await storageRef
              .getDownloadURL()
              .then((url) => {
                this.musicShareLink = url.split('?')[0] + '?alt=media';
                console.log('music link >>>', this.musicShareLink);
                this.loadingMerge = false;
              })
              .catch(() => (this.loadingMerge = false));
          })
          .catch(() => (this.loadingMerge = false));

        // console.log('BLOB SUBIDO', this.musicBlob);

        const dataView = new DataView(reader.result as ArrayBuffer);

        // Verifica si el archivo WAV tiene el formato correcto
        const riffChunkID = dataView.getUint32(0, true);
        if (riffChunkID !== 0x46464952) {
          console.error('El archivo no tiene formato WAV válido');
          return;
        }

        // Obtiene la duración del audio
        const subChunk2Size = dataView.getUint32(40, true);
        const sampleRate = dataView.getUint32(24, true);
        const numChannels = dataView.getUint16(22, true);
        const bitsPerSample = dataView.getUint16(34, true);
        this.musicDurationSeconds = (subChunk2Size / (sampleRate * numChannels * (bitsPerSample / 8))).toFixed(1);

        console.log('Duración del audio:', this.musicDurationSeconds, 'segundos');
      };
      reader.readAsArrayBuffer(file);
    }
  }

  // getAudioDuration() {
  //   if (this.audioElement && this.audioElement.nativeElement) {
  //     const audio = this.audioElement.nativeElement as HTMLAudioElement;
  //     this.joinedDuration = `${audio.duration}`;
  //   }
  // }

  async createAudioFile(blob: Blob): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        const data = new Uint8Array(reader.result as ArrayBuffer);
        const audioFile = new File([data], 'temp_audio', {type: blob.type});
        resolve(URL.createObjectURL(audioFile));
      };
      reader.readAsArrayBuffer(blob);
    });
  }

  getAudioBlobFromURL(url) {
    return fetch(url).then((response) => response.blob());
  }

  getProxyURL(url) {
    return url
      .replace('https://firebasestorage.googleapis.com', 'https://proxy.dev.bookline.io/firebase_storage')
      .replace('https://storage.googleapis.com', 'https://proxy.dev.bookline.io/storage_google');
  }

  async mergeAudioWithMusic() {
    this.loadingMerge = true;
    try {
      const ffmpeg = createFFmpeg({log: true});
      if (!ffmpeg.isLoaded()) await ffmpeg.load();

      await this.getAudioBlobFromURL(this.getProxyURL(this.musicShareLink))
        .then((audioBlob) => {
          this.musicBlob = audioBlob;
          console.log('MUSIC BLOB FETCHED', audioBlob);
        })
        .catch((error) => {
          console.error('Error fetching audio blob:', error);
        });

      // Convert Blobs to audio files
      console.log('musicBlob >>>', this.musicBlob);

      const file1 = await this.createAudioFile(await this.buildSingleFilterComplex(this.musicBlob, 'music'));

      const joinTheSilenceBlob = await this.mergeAudioFiles([this.joinedBlob, await this.createSilentAudioBlob(this.silentSeconds)]);
      // const joinTheSilenceBlob = await this.mergeAudioFiles([this.joinedBlob, this.generateSilentAudio(20)]);

      const file2 = await this.createAudioFile(joinTheSilenceBlob);

      // Run FFmpeg to merge the audio files
      ffmpeg.FS('writeFile', 'input1.mp3', await fetchFile(file1));
      ffmpeg.FS('writeFile', 'input2.mp3', await fetchFile(file2));
      await ffmpeg.run(
        '-i',
        'input1.mp3',
        '-i',
        'input2.mp3',
        '-filter_complex',
        `[0:a]volume=${this.musicVolume}[a1];[a1][1:a]amerge,lowpass=f=10000`,
        '-c:a',
        'libmp3lame',
        '-q:a',
        '4',
        '-t',
        '30',
        'output.mp3'
      );

      // Get the merged audio file
      const mergedData = ffmpeg.FS('readFile', 'output.mp3');
      this.mergedBlob = new Blob([mergedData.buffer], {type: 'audio/mpeg'});

      // Clean up
      ffmpeg.FS('unlink', 'input1.mp3');
      ffmpeg.FS('unlink', 'input2.mp3');
      ffmpeg.FS('unlink', 'output.mp3');
      ffmpeg.exit();

      const src = window.URL.createObjectURL(this.mergedBlob);
      this.audioWithMusicURL = src;
      this.loadingMerge = false;
    } catch {
      this.loadingMerge = false;
    }
  }

  async createSilentAudioBlob(secondsUtil) {
    let secondsUtil1 = '0.1';
    if (secondsUtil > 0.1) {
      secondsUtil1 = secondsUtil.toString();
    }
    if (!ffmpeg.isLoaded()) await ffmpeg.load();
    // Run FFmpeg to generate the silent audio
    const silence_name = 'silentOutput.mp3';
    console.log('are u waiting?');
    await ffmpeg.run('-f', 'lavfi', '-i', 'anullsrc=r=44100:cl=mono', '-t', secondsUtil1, '-c:a', 'libmp3lame', silence_name);
    console.log('waited');
    // Get the silent audio data
    const silentData = ffmpeg.FS('readFile', silence_name);
    console.log('silentData', silentData);
    const silentBlob = new Blob([silentData.buffer], {type: 'audio/mpeg'});
    console.log('silentBlob', silentBlob);

    // Clean up
    ffmpeg.FS('unlink', silence_name);
    ffmpeg.exit();
    return silentBlob;
  }

  getVoicesFiltered(index) {
    let voiceUtil = voiceOptions.tts.filter((voi: any) => {
      if (this.toolsArray[index].gender === voi.gender && this.toolsArray[index].lang === voi.language && this.toolsArray[index].provider === voi.provider) return true;

      if (this.toolsArray[index].gender === '' && this.toolsArray[index].lang === voi.language && this.toolsArray[index].provider === voi.provider) return true;

      if (this.toolsArray[index].gender === '' && this.toolsArray[index].lang === '' && this.toolsArray[index].provider === voi.provider) return true;

      if (this.toolsArray[index].gender === '' && this.toolsArray[index].lang === '' && this.toolsArray[index].provider === '') return true;

      if (this.toolsArray[index].gender === '' && this.toolsArray[index].lang === voi.language && this.toolsArray[index].provider === '') return true;

      if (this.toolsArray[index].gender === voi.gender && this.toolsArray[index].lang === voi.language && this.toolsArray[index].provider === '') return true;

      if (this.toolsArray[index].gender === voi.gender && this.toolsArray[index].lang === '' && this.toolsArray[index].provider === voi.provider) return true;

      if (this.toolsArray[index].gender === voi.gender && this.toolsArray[index].lang === '' && this.toolsArray[index].provider === '') return true;

      return false;
    });

    return voiceUtil;
  }

  async joinBlobs() {
    this.loadingMerge = true;
    let toolsArrayUtil = [...this.toolsArray];

    // console.log('toolsArrayUtil'), toolsArrayUtil;

    for (let i = 0; i < toolsArrayUtil.length; i++) {
      if (toolsArrayUtil[i].audioURL === '') {
        console.log('empieza blob > ', i);

        await this.generateVoice(i);
        console.log('termina blob > ', i);
      }
    }
    // console.log('sale del for ', this.toolsArray);

    if (this.initialPause !== 0) {
      const silentBlobUtil = await this.createSilentAudioBlob(this.initialPause);
      toolsArrayUtil.unshift({blob: silentBlobUtil});
    }

    const audioBlobs = [];

    for (let tool of toolsArrayUtil) {
      audioBlobs.push(tool.blob);
      this.joinedBlob = await this.mergeAudioFiles(audioBlobs);
      this.joinedURL = window.URL.createObjectURL(this.joinedBlob);
    }

    this.loadingMerge = false;

    // console.log('Joined URL', this.joinedURL);
  }

  async generateVoice(index) {
    this.linkBool = false;
    this.toolsArray[index].shareLink = '';

    if (this.toolsArray[index].voice) {
      this.toolsArray[index].voice['tts'] = this.toolsArray[index].ttsText;

      if (this.toolsArray[index].voice.provider === 'AMAZON') {
        try {
          const res = await this.ps.generateAmazonVoice(this.toolsArray[index].voice);
          const reader = res.AudioStream.getReader();

          const chunks = [];
          let size = 0;

          // Read the stream and push chunks to the array
          while (true) {
            const {done, value} = await reader.read();
            if (done) break;

            chunks.push(value);
            size += value.length;
          }

          // Concatenate all chunks into a single Uint8Array
          const uint8Array = new Uint8Array(size);
          let offset = 0;

          for (let chunk of chunks) {
            uint8Array.set(chunk, offset);
            offset += chunk.length;
          }

          // Convert the content string into a Blob
          const audioBlob = new Blob([uint8Array.buffer], {type: 'audio/mpeg'});

          this.toolsArray[index].blob = await this.buildSingleFilterComplex(audioBlob, index);

          const src = window.URL.createObjectURL(this.toolsArray[index].blob);
          this.toolsArray[index].audioURL = src;
        } catch (err) {
          console.log(err);
        }
      } else if (this.toolsArray[index].voice.provider === 'MICROSOFT') {
        try {
          const arrayBuffer = await this.ps.generateMicrosoftVoice(this.toolsArray[index].voice).toPromise();
          const audioBlob = new Blob([arrayBuffer], {type: 'audio/mpeg'});

          this.toolsArray[index].blob = await this.buildSingleFilterComplex(audioBlob, index);

          const src = window.URL.createObjectURL(this.toolsArray[index].blob);
          this.toolsArray[index].audioURL = src;
        } catch (err) {
          console.log(err);
        }
      } else if (this.toolsArray[index].voice.provider === 'GOOGLE') {
        try {
          const respo = await this.ps.generateGoogleVoice(this.toolsArray[index].voice).toPromise();
          const binaryString = atob(respo.audioContent);
          const arrayBuffer = new ArrayBuffer(binaryString.length);
          const bufferView = new Uint8Array(arrayBuffer);
          for (let i = 0; i < binaryString.length; i++) {
            bufferView[i] = binaryString.charCodeAt(i);
          }

          const audioBlob = new Blob([arrayBuffer], {type: 'audio/mpeg'});

          this.toolsArray[index].blob = await this.buildSingleFilterComplex(audioBlob, index);

          const src = window.URL.createObjectURL(this.toolsArray[index].blob);
          this.toolsArray[index].audioURL = src;
        } catch (err) {
          console.log(err);
        }
      }
    }
  }

  addMusic() {
    this.wantMerge = !this.wantMerge;
  }

  copyToClipboard(index: any) {
    if (index === 'joined') {
      navigator.clipboard.writeText(this.shareLinkJoined);
    } else if (index === 'merged') {
      navigator.clipboard.writeText(this.shareLinkMerged);
    } else {
      navigator.clipboard.writeText(this.toolsArray[index].shareLink);
    }
    document.getElementById('custom-tooltip').style.display = 'inline';
    setTimeout(function () {
      document.getElementById('custom-tooltip').style.display = 'none';
    }, 1000);
  }

  generateLink(index) {
    this.linkBool = true;
    this.showFullLink = false;

    // Get a reference to a file
    const timestamp = Date.now();
    const randomString = 'audio';
    let fileName = '';
    if (this.toolsArray[index].customFileName !== '') {
      fileName = `${this.toolsArray[index].customFileName.trim().replace(/ /g, '-')}-${randomString}-${timestamp}.mp3`;
    } else {
      fileName = `${randomString}-${timestamp}.mp3`;
    }

    const storageRef = this.storage.ref(this.currentUser.email + '/' + fileName);

    // Upload a file
    storageRef
      .put(this.toolsArray[index].blob)
      .then((res) => {
        storageRef
          .getDownloadURL()
          .then((url) => {
            const newURL = url.split('?')[0] + '?alt=media';
            this.toolsArray[index].shareLink = newURL;
            this.toolsArray[index].customFileName = '';
          })
          .catch((error) => {
            console.error('Error getting download URL:', error);
          });
      })
      .catch((error) => {
        console.error('Error uploading file:', error);
      });
  }
  generateJoinedLink() {
    this.linkBoolJoined = true;
    this.showFullLinkJoined = false;
    const storage = firebase.storage();

    // Get a reference to a file
    const timestamp = Date.now();
    const randomString = 'audio';
    let fileName = '';
    if (this.customFileNameJoined !== '') {
      fileName = `${this.customFileNameJoined.trim().replace(/ /g, '-')}-${randomString}-${timestamp}.mp3`;
    } else {
      fileName = `${randomString}-${timestamp}.mp3`;
    }

    const storageRef = storage.ref(this.currentUser.email + '/' + fileName);

    // Upload a file
    storageRef
      .put(this.joinedBlob)
      .then((res) => {
        storageRef
          .getDownloadURL()
          .then(async (url) => {
            const newURL = url.split('?')[0] + '?alt=media';
            this.shareLinkJoined = newURL;
            this.customFileNameJoined = '';
            await this.updateAudioPAPI(newURL);
          })
          .catch((error) => {
            console.error('Error getting download URL:', error);
          });
      })
      .catch((error) => {
        console.error('Error uploading file:', error);
      });
  }

  getIdFromURL(url) {
    const regex = /audio-(\d+)\.mp3/;
    const match = url.match(regex);

    if (match && match.length > 1) {
      return match[1];
    }

    return null;
  }

  updateAudioPAPI(url) {
    const payload = {
      id: this.getIdFromURL(url),
      tracks: this.toolsArray.map((langTool) => {
        return {
          voice: langTool.voice.code,
          text: langTool.ttsText,
          volume: langTool.customVolume,
        };
      }),
      url: url,
      initial_pause: this.initialPause,
      between_pause: this.betweenPause,
      background_track: {
        url: this.musicShareLink,
        volume: this.musicVolume,
      },
    };

    this.papi_subs1 = this.papi.addAudio(payload).subscribe((res) => {
      console.log('audio post', res);
    });
  }

  generateMergedLink() {
    console.log('toolsArray', this.toolsArray);

    this.linkBoolMerged = true;
    this.showFullLinkMerged = false;
    const storage = firebase.storage();

    // Get a reference to a file

    const timestamp = Date.now();
    const randomString = 'audio';
    let fileName = '';
    if (this.customFileNameMerged !== '') {
      fileName = `${this.customFileNameMerged.trim().replace(/ /g, '-')}-${randomString}-${timestamp}.mp3`;
    } else {
      fileName = `${randomString}-${timestamp}.mp3`;
    }

    const storageRef = storage.ref(this.currentUser.email + '/' + fileName);

    // Upload a file
    storageRef
      .put(this.mergedBlob)
      .then((res) => {
        storageRef
          .getDownloadURL()
          .then((url) => {
            const newURL = url.split('?')[0] + '?alt=media';
            this.shareLinkMerged = newURL;
            this.customFileNameMerged = '';

            this.updateAudioPAPI(newURL);
          })
          .catch((error) => {
            console.error('Error getting download URL:', error);
          });
      })
      .catch((error) => {
        console.error('Error uploading file:', error);
      });
  }

  newAudio() {
    // console.log('new audio');
    console.log('toolsArray', this.toolsArray);
    this.toolsArray.push({
      languages: this.languages,
      providers: this.providers,
      genders: this.genders,
      customFileName: '',
      shareLink: '',
      ttsText: '',
      lang: '',
      gender: '',
      provider: '',
      voice: null,
      customVolume: 1,
      audioURL: '',
    });
  }

  handleAudioEvent(event) {
    this.joinedDuration = `${event.target.duration.toFixed(1)}`;
  }

  async mergeAudioFiles(audioBlobs) {
    this.loadingMerge = true;
    const outputFileName = 'output1.mp3';
    if (!ffmpeg.isLoaded()) await ffmpeg.load();
    // const audioListNames = audioBlobs.map((blob, index) => 'audio' + index.toString() + '.mp3');

    let command = [];
    for (let i = 0; i < audioBlobs.length; i++) {
      const name = `audio${i}.mp3`;
      ffmpeg.FS('writeFile', name, await fetchFile(audioBlobs[i]));
      command.push('-i', name);
    }

    try {
      command.push('-filter_complex', this.buildFilterComplex(audioBlobs.length).replace(';concat', 'concat'), '-map', '[out]', outputFileName);
      // console.log(command);
      await ffmpeg.run(...command);
      // console.log('Audio files merged successfully');
    } catch (error) {
      this.loadingMerge = false;

      console.error('Error merging audio files:', error);
    }

    const output = ffmpeg.FS('readFile', outputFileName);
    // console.log(output);
    ffmpeg.exit();

    return new Blob([output.buffer], {type: 'audio/mpeg'});
  }

  buildFilterComplex(numAudios: number): string {
    let filter = '';
    let concatInputs = '';

    for (let i = 0; i < numAudios; i++) {
      if (this.initialPause !== 0) {
        if (i <= 1) {
          filter += `[${i}:a]volume=1[a${i + 1}];`;
          concatInputs += `[a${i + 1}]`;
        } else {
          filter += `[${i}:a]volume=1[a${i + 1}];[a${i + 1}]adelay=${this.betweenPause * 1000}[a${i + 1}delayed];`;
          concatInputs += `[a${i + 1}delayed]`;
        }
      } else {
        if (i === 0) {
          filter += `[${i}:a]volume=1[a${i + 1}];`;
          concatInputs += `[a${i + 1}]`;
        } else {
          filter += `[${i}:a]volume=1[a${i + 1}];[a${i + 1}]adelay=${this.betweenPause * 1000}[a${i + 1}delayed];`;
          concatInputs += `[a${i + 1}delayed]`;
        }
      }
    }

    filter += `${concatInputs}concat=n=${numAudios}:v=0:a=1[out]`;
    return filter;
  }

  async buildSingleFilterComplex(blobUtil, index) {
    let filter = '';
    // if (index === 0) {
    //   console.log('ENTRA ACA');

    //   // filter = `[0:a]volume=${this.toolsArray[index].customVolume}[a1];[a1]adelay=${this.initialPause * 1000}[a1delayed]`;
    //   filter = `[0:a]volume=${this.toolsArray[index].customVolume}[a1];[a1]adelay=${this.initialPause * 1000}[a1]`;
    //   // filter = `[0:a]volume=${this.toolsArray[index].customVolume}[a1][a1delayed][a1]adelay=${this.initialPause}[a1delayed]`;
    // } else
    if (index === 'music') {
      filter = `[0:a]volume=${this.musicVolume}[a1]`;
    } else {
      filter = `[0:a]volume=${this.toolsArray[index].customVolume}[a1]`;
    }

    // `[0:a]volume=${this.toolsArray[index].customVolume}[a1];[a1]adelay=${this.initialPause * 1000}[a1delayed];[a1delayed]`;

    const outputFileName = `test${index}.mp3`;

    if (!ffmpeg.isLoaded()) await ffmpeg.load();
    ffmpeg.FS('writeFile', 'input.blob', await fetchFile(blobUtil));

    const command = ['-i', 'input.blob', '-filter_complex', filter, '-map', '[a1]', outputFileName];
    // console.log('COMMAND >>>>>', ...command);

    await ffmpeg.run(...command);

    const output = ffmpeg.FS('readFile', outputFileName);
    ffmpeg.FS('unlink', 'input.blob'); // Cleanup input file
    ffmpeg.FS('unlink', outputFileName); // Cleanup output file
    ffmpeg.exit();

    return new Blob([output.buffer], {type: 'audio/mpeg'});
  }

  clearAudioURL(index) {
    this.toolsArray[index].audioURL = '';
  }
  showVolume(index, event) {
    this.toolsArray[index].customVolume = event.value;
  }

  checkMerge() {
    return this.musicBlob == null || this.joinedURL == '';
  }

  save() {
    // if (!this.languageForm.valid) {
    //   this.toastrService.show("Error", "All languages are required", {status: "danger"});
    //   return false;
    // }
    this.ref.close(this.finalURL);
    // this.ref.close(this.getJSON());
  }

  dismiss() {
    this.ref.close(null);
  }

  clear() {
    this.ref.close('');
  }

  ngOnDestroy() {
    if (this.dialog_subs) this.dialog_subs.unsubscribe();
  }
}
