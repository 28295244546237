import {Component, Input, OnInit, Output, Pipe, PipeTransform, ViewChild} from '@angular/core';
import {ControlContainer, FormGroup, FormGroupDirective, FormArray} from '@angular/forms';
import {NbDialogService} from '@nebular/theme';
import {ProxyService} from '../../../@core/api/proxy.service';
import {Subscription} from 'rxjs';
import * as firebase from 'firebase';
import {UserService} from '../../../@core/firestore/user.service';
import {createFFmpeg, fetchFile} from '@ffmpeg/ffmpeg';

@Component({
  selector: 'ngx-image-input',
  styleUrls: ['./image-input.component.scss'],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    },
  ],
  templateUrl: './image-input.component.html',
})
export class ImageInputComponent implements OnInit {
  @Input() controlName: string;
  @Input() formGroup: FormGroup;

  form: FormGroup;
  proxy_subs: Subscription;
  audioURL: string = '';
  storage: any;
  uploadLoading: boolean = false;
  currentUser: firebase.User;
  uploadedFileURL: string = '';

  constructor(public controlContainer: ControlContainer, public ps: ProxyService, private userService: UserService) {}

  ngOnInit() {
    this.storage = firebase.storage();
    this.userService
      .getCurrentUser()
      .then((user) => {
        this.currentUser = user;
      })
      .catch((err) => console.log(err));

    if (this.formGroup != null) {
      this.form = this.formGroup;
    } else {
      this.form = <FormGroup>this.controlContainer.control;
    }
  }

  handleUploadFile(event: any) {
    const file: File = event.target.files[0];
    // this.createAudioBlobFromFile(file);
    console.log('FILE >>', file);
    this.uploadFile(file);
  }

  onDrop(event: any) {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    console.log('file>>>>', file);
    console.log('storage>>>>', this.storage);

    this.uploadFile(file);
  }

  onDragOver(event: any) {
    event.preventDefault();
  }

  onDragLeave(event: any) {
    event.preventDefault();
  }

  uploadFile1(file: File) {
    this.uploadLoading = true;

    const storageRef = this.storage.ref(this.currentUser.email + '/' + file.type + '/' + Date.now().toString().slice(-3) + '-' + file.name);

    const task = storageRef
      .put(file)
      .then((res) => {
        storageRef
          .getDownloadURL()
          .then((url) => {
            console.log('ARCHIVO SUBIDO', url);
            this.uploadLoading = false;
            this.uploadedFileURL = url;
            this.form.get(this.controlName).setValue(url);
          })
          .catch((error) => {
            console.error('Error getting download URL:', error);
            this.uploadLoading = false;
          });
      })
      .catch((error) => {
        console.error('Error uploading file:', error);
      });
  }

  async uploadFile2(file) {
    this.uploadLoading = true;

    const isJpg = file.type === 'image/jpeg' || file.type === 'image/jpg';

    let fileName = Date.now().toString().slice(-3) + '-' + file.name;
    let fileUtil = file;

    if (!isJpg) {
      const ffmpeg = createFFmpeg({log: true});
      if (!ffmpeg.isLoaded()) await ffmpeg.load();

      ffmpeg.FS('writeFile', file.name, await fetchFile(file));

      ffmpeg.run('-i', file.name, 'output.jpeg');
      fileUtil = window.URL.createObjectURL(new Blob([ffmpeg.FS('readFile', 'output.jpeg').buffer], {type: 'image/jpeg'}));
      fileName = fileName.split('.')[0] + '.jpeg';
    }
    const filePath = this.currentUser.email + '/image/' + fileName;
    const storageRef = this.storage.ref(filePath);
    storageRef
      .put(fileUtil)
      .then(() => {
        console.log('File uploaded successfully');
        this.uploadLoading = false;
        storageRef
          .getDownloadURL()
          .then((url) => {
            console.log('ARCHIVO SUBIDO', url);
            this.uploadedFileURL = url;
            this.form.get(this.controlName).setValue(url);
          })
          .catch((error) => {
            console.error('Error getting download URL:', error);
          });
      })
      .catch((error) => {
        console.error('Error uploading file:', error);
        this.uploadLoading = false;
      });
  }
  async uploadFile(file) {
    this.uploadLoading = true;

    const isJpg = file.type === 'image/jpeg' || file.type === 'image/jpg';

    let fileName = Date.now().toString().slice(-3) + '-' + file.name;
    let fileUtil = file;

    if (!isJpg) {
      const ffmpeg = createFFmpeg({log: true});
      if (!ffmpeg.isLoaded()) await ffmpeg.load();

      ffmpeg.FS('writeFile', file.name, await fetchFile(file));

      await ffmpeg.run('-i', file.name, '-vf', "format=yuva444p,geq='if(lte(alpha(X,Y),16),255,p(X,Y))':'if(lte(alpha(X,Y),16),128,p(X,Y))':'if(lte(alpha(X,Y),16),128,p(X,Y))'", 'output.jpeg');
      const outputData = ffmpeg.FS('readFile', 'output.jpeg');

      fileUtil = new File([outputData.buffer], 'output.jpeg', {type: 'image/jpeg'});
      fileName = fileName.split('.')[0] + '.jpeg';
    }

    const filePath = this.currentUser.email + '/image/' + fileName;
    const storageRef = this.storage.ref(filePath);

    storageRef
      .put(fileUtil)
      .then(() => {
        console.log('File uploaded successfully');
        storageRef
          .getDownloadURL()
          .then((url) => {
            console.log('ARCHIVO SUBIDO', url);
            this.uploadedFileURL = url;
            this.form.get(this.controlName).setValue(url);
            this.uploadLoading = false;
          })
          .catch((error) => {
            console.error('Error getting download URL:', error);
            this.uploadLoading = false;
          });
      })
      .catch((error) => {
        console.error('Error uploading file:', error);
        this.uploadLoading = false;
      });
  }

  getURL() {
    return this.form
      .get(this.controlName)
      .value.replace('https://firebasestorage.googleapis.com', 'https://proxy.dev.bookline.io/firebase_storage')
      .replace('https://storage.googleapis.com', 'https://proxy.dev.bookline.io/storage_google');
  }
}
