import {Router, ActivatedRoute} from '@angular/router';
import {AuthService} from './../../../@core/firestore/auth.service';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {NbMediaBreakpointsService, NbMenuService, NbSidebarService, NbThemeService} from '@nebular/theme';

import {map, takeUntil, filter} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {NGXLogger} from 'ngx-logger';
import * as firebase from 'firebase/app';
import 'firebase/storage';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {
  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  user: any;
  currentTheme = 'cosmic';
  isEnviromenteProd: boolean;

  userMenu = [{title: 'Profile'}, {title: 'Log out'}];
  storage: any;
  customMessage: string = '';
  textColor: string = '';

  constructor(
    private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private themeService: NbThemeService,
    private route: ActivatedRoute,
    private router: Router,
    private auth: AuthService,
    private breakpointService: NbMediaBreakpointsService,
    private logger: NGXLogger
  ) {
    this.isEnviromenteProd = environment.production;
  }

  ngOnInit() {
    this.themeService.changeTheme('corporate');

    this.storage = firebase.storage();
    this.setBgImage();

    this.route.data.subscribe((routeData) => {
      const data = routeData['data'];
      if (data) {
        this.user = data;
      }
    });

    this.menuService
      .onItemClick()
      .pipe(
        filter(({tag}) => tag === 'my-context-menu'),
        map(({item: {title}}) => title)
      )
      .subscribe((title) => {
        if (title === 'Profile') {
          this.router.navigate(['/user']);
        } else if (title === 'Log out') {
          this.auth.doLogout().then(
            (res) => {
              this.router.navigate(['/']);
            },
            (error) => {
              this.logger.error('Logout error', error);
            }
          );
        }
      });

    const {xl} = this.breakpointService.getBreakpointsMap();
    this.themeService
      .onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$)
      )
      .subscribe((isLessThanXl: boolean) => (this.userPictureOnly = isLessThanXl));

    this.themeService
      .onThemeChange()
      .pipe(
        map(({name}) => name),
        takeUntil(this.destroy$)
      )
      .subscribe((themeName) => (this.currentTheme = themeName));
  }

  getURL(url) {
    return url
      .replace('https://firebasestorage.googleapis.com', 'https://proxy.dev.bookline.io/firebase_storage')
      .replace('https://storage.googleapis.com', 'https://proxy.dev.bookline.io/storage_google');
  }
  fetchJsonData(url: string) {
    fetch(this.getURL(url), {mode: 'cors'})
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        this.customMessage = data.text;
        this.textColor = data.color;

        const headerElement: any = document.querySelector('nb-layout-header');

        // if (headerElement) {
        //   // FIXME: remove after christmas
        //   // headerElement.style.backgroundImage = `url('${this.getURL(data.url)}')`;
        //   // headerElement.crossorigin = 'anonymous';
        //   this.logger.debug('Header Element');
        //   this.logger.debug(headerElement);
        // } else {
        //   this.logger.warn('nb-layout-header element not found.');
        // }
        // Process the retrieved data as needed
      })
      .catch((error) => {
        console.error('Error fetching JSON file:', error);
      });
  }

  async setBgImage() {
    const filePath = 'admin-custom/data.json';
    const fileRef = this.storage.ref(filePath);
    fileRef.getDownloadURL().then((data) => {
      this.fetchJsonData(data);
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    return false;
  }
  hardRefresh() {
    localStorage.clear();
    window.location.reload();
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }
}
